export class BSException {
    error: string;
    error_message: string;
    code?: number;

    constructor(error: string, error_message: string, code = 0) {
        this.error = error;
        this.error_message = error_message;
        this.code = code;

        console.error(`BSException: ${error} - ${error_message} - ${code}`);
    }
}