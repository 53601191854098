import React, {useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import { ReactComponent as ImprintBuilderMenuShapeIcon } from "../../assets/imprint-builder/shape.svg";
import { ReactComponent as ImprintBuilderMenuBorderColorIcon } from "../../assets/imprint-builder/line_color.svg";
import { ReactComponent as ImprintBuilderMenuBackgroundColorIcon } from "../../assets/imprint-builder/fill_color.svg";
import { ReactComponent as ImprintBuilderMenuLineWeightIcon } from "../../assets/imprint-builder/line_weight.svg";
import { ReactComponent as ImprintBuilderMenuTextAreaIcon } from "../../assets/imprint-builder/text_area.svg";
import { ReactComponent as ImprintBuilderMenuImageIcon } from "../../assets/imprint-builder/image.svg";
import { ReactComponent as ImprintBuilderSelectedIcon } from "../../assets/imprint-builder/selected.svg";
import { ReactComponent as BorderStyleIcon } from "../../assets/imprint-builder/border_style.svg";
import transparentBg from "../../assets/imprint-builder/transparent.png";
import DeleteIcon from "@mui/icons-material/Delete";
import ImageIcon from '@mui/icons-material/Image';
import FlipToFrontIcon from "@mui/icons-material/FlipToFront";
import FlipToBackIcon from "@mui/icons-material/FlipToBack";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import AlignHorizontalLeft from "@mui/icons-material/AlignHorizontalLeft";
import AlignHorizontalCenter from "@mui/icons-material/AlignHorizontalCenter";
import AlignHorizontalRight from "@mui/icons-material/AlignHorizontalRight";
import { Property } from 'csstype';
import yaml from 'yaml';

import "./BSImprintBuilder.scss";
import {Menu, MenuItem, Tooltip} from "@mui/material";
import useFormatters from "../../hooks/use-formatters";
import {StepperContext} from "../../contexts/stepper.context";
import {resizeBase64Png} from "../../utils";

export interface BSImprintBuilderProps {
    width: number;
    height: number;
    style: React.CSSProperties;
}

export interface BSImprintBuilderMethods {
    getImageData(): Promise<string>;
}

export enum ImprintBuilderObjectType {
    RECTANGLE = "RECTANGLE",
    ROUND = "ROUND",
    IMAGE = "IMAGE",
    TEXTAREA = "TEXTAREA"
}

export enum ImprintBuilderBorderStyle {
    SOLID = "SOLID",
    DOUBLE = "DOUBLE",
    DASHED = "DASHED"
}

export interface ImprintBuilderObject {
    id: number;
    type: ImprintBuilderObjectType;
    x: number;
    y: number;
    zindex: number;
    width?: number;
    height?: number;
    fillColor: string;
    borderColor?: string;
    borderWeight?: number;
    borderStyle?: ImprintBuilderBorderStyle;
    radius?: number;
    src?: HTMLImageElement;
    text?: string;
    font?: string;
    fontSize?: number;
    fontStyle?: "normal" | "italic",
    fontWeight?: "normal" | "bold",
    alignment?: "Left" | "Center" | "Right";
    draggable: boolean;
    resizable: boolean;
    zindexable: boolean;
    resizeMode?: "free" | "keep-aspect";
    deletable: boolean;
}

export enum ImprintBuilderHistoryEntryType {
    ADD = "ADD",
    UPDATE = "UPDATE",
    REMOVE = "REMOVE",
    ORDER = "ORDER"
}

export interface ImprintBuilderHistoryEntry {
    type: ImprintBuilderHistoryEntryType,
    object: ImprintBuilderObject | number[]
}

export interface K11GraphicsShapeWithContent {
    shape: {
        width: number;
        height: number;
        stroke: number;
        radius: number;
        stroke_color: { rgba: number[] };
        fill_color: { rgba: number[] };
    },
    pos: {
        x: number;
        y: number;
        v_align: "Top" | "Bottom" | "None",
        h_align: "Left" | "Center" | "Right" | "None"
    };
    alpha: null | number;
    content: string | any[];
}

export interface YamlElement {
    shape: {
        width: number;
        height: number;
        stroke: number;
        radius: number;
        stroke_color: { rgba: number[] };
        fill_color: { rgba: number[] };
    },
    pos: {
        x: number;
        y: number;
        v_align: "Top" | "Bottom" | "None",
        h_align: "Left" | "Center" | "Right" | "None"
    };
    alpha: null | number;
    content: string | any[];
}

export interface YamlTextContent {
    text: string;
    font_name: string;
    font_color: { rgba: number[] };
    font_size: number;
    align: "Left" | "Center" | "Right";
    space_before: number;
    space_after: number;
}

export interface YamlImageContent {
    content: string;
}

export interface YamlTextElement extends YamlElement {
    content: YamlTextContent[];
}

const BSImprintBuilder = React.forwardRef((props: BSImprintBuilderProps, ref) => {

    const { formatMessage } = useFormatters();

    const {
        imprintYmlModel,
        imprintBuilderNextId,
        imprintBuilderObjects,
        imprintBuilderHistoryUndo,
        imprintBuilderHistoryRedo,
        fontsList,
        fontsAvailableVariants,
        outOfImprintBounds,
        setOutOfImprintBounds,
    } = useContext(StepperContext);

    // Object properties set/updated by the UI
    const [shape, setShape] = useState<string>((imprintBuilderObjects.current[0] && imprintBuilderObjects.current[0].type === ImprintBuilderObjectType.ROUND ? "round" : "rectangle"));
    const [borderColor, setBorderColor] = useState<string>("#000000FF");
    const [backgroundColor, setBackgroundColor] = useState<string>("#00000000");
    const [borderWeight, setBorderWeight] = useState<number>(1);
    const [borderStyle, setBorderStyle] = useState<ImprintBuilderBorderStyle>(ImprintBuilderBorderStyle.SOLID);
    const [font, setFont] = useState<string>("Arial");
    const [fontSize, setFontSize] = useState<number>(30);
    const [fontStyle, setFontStyle] = useState<"normal" | "italic">("normal");
    const [fontWeight, setFontWeight] = useState<"normal" | "bold">("normal");
    const [textAlign, setTextAlign] = useState<"Left" | "Center" | "Right">("Left");

    // UI submenus states
    const [openShapeMenu, setOpenShapeMenu] = useState<boolean>(false);
    const [openBorderColorMenu, setOpenBorderColorMenu] = useState<boolean>(false);
    const [openBackgroundColorMenu, setOpenBackgroundColorMenu] = useState<boolean>(false);
    const [openBorderWeightMenu, setOpenBorderWeightMenu] = useState<boolean>(false);
    const [openBorderStyleMenu, setOpenBorderStyleMenu] = useState<boolean>(false);

    // UI menu buttons refs
    const shapeMenuButton = useRef<HTMLDivElement>(null);
    const borderColorMenuButton = useRef<HTMLDivElement>(null);
    const backgroundColorMenuButton = useRef<HTMLDivElement>(null);
    const borderWeightMenuButton = useRef<HTMLDivElement>(null);
    const borderStyleMenuButton = useRef<HTMLDivElement>(null);

    // Canvas ref, objects in canvas ref & other canvas states
    const drawingCanvasRef = useRef<HTMLCanvasElement>(null);
    const selectionPopupRef = useRef<HTMLDivElement>(null);
    const undoRedoPopupRef = useRef<HTMLDivElement>(null);
    const textAreaPopupRef = useRef<HTMLDivElement>(null);
    const editingTextAreaRef = useRef<HTMLTextAreaElement>(null);

    const [dragging, setDragging] = useState(false);
    const [resizing, setResizing] = useState(false);
    const [allowMagnetism, setAllowMagnetism] = useState(true);

    const [hoverSelectableObject, setHoverSelectableObject] = useState(false);
    const [hoverSelectedAndDraggableObject, setHoverSelectedAndDraggableObject] = useState(false);
    const [currentResizeHandle, setCurrentResizeHandle] = useState<"top-left" | "top-right" | "bottom-left" | "bottom-right" | null>(null);
    const [currentResizeBorder, setCurrentResizeBorder] = useState<"top" | "right" | "left" | "bottom" | null>(null);

    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const [selectedObject, setSelectedObject] = useState<ImprintBuilderObject | null>(null);
    const [selectedObjectPreviousState, setSelectedObjectPreviousState] = useState<ImprintBuilderObject | null>(null);
    const [textAreaPreviousContent, setTextAreaPreviousContent] = useState<string>("");
    const [leftAlignLine, setLeftAlignLine] = useState(false);
    const [rightAlignLine, setRightAlignLine] = useState(false);
    const [topAlignLine, setTopAlignLine] = useState(false);
    const [bottomAlignLine, setBottomAlignLine] = useState(false);
    const [horizontalCenterAlignLine, setHorizontalCenterAlignLine] = useState(false);
    const [verticalCenterAlignLine, setVerticalCenterAlignLine] = useState(false);

    const [tooltipsPosition, setTooltipsPosition] = useState("bottom");

    const [yamlParsingCompleted, setYamlParsingCompleted] = useState(false);

    const [showSelectionPopup, setShowSelectionPopup] = useState(false);
    const [selectionPopupPosition, setSelectionPopupPosition] = useState({top: 0, left: 0});

    const [showTextAreaPopup, setShowTextAreaPopup] = useState(false);
    const [textAreaPopupPosition, setTextAreaPopupPosition] = useState({top: 0, left: 0});
    const [disabledFontVariants, setDisabledFontVariants] = useState(false);
    const [disabledBorderColor, setDisabledBorderColor] = useState(false);

    const [editedTextAreaObject, setEditedTextAreaObject] = useState<ImprintBuilderObject | null>(null);
    const [showEditingTextArea, setShowEditingTextArea] = useState(false);
    const [editingTextAreaPosition, setEditingTextAreaPosition] = useState({top: 0, left: 0});
    const [editingTextAreaSize, setEditingTextAreaSize] = useState({width: 100, height: 100});
    const [editingTextAreaFont, setEditingTextAreaFont] = useState("30px Arial");
    const [editingTextAreaLineHeight, setEditingTextAreaLineHeight] = useState(5);
    const [editingTextAreaColor, setEditingTextAreaColor] = useState("#000000FF");
    const [editingTextAreaAlignment, setEditingTextAreaAlignment] = useState("left");

    // Other const
    const scaleFactor = 0.83;
    const selectionBoxOffset = 6;
    const selectionHandlesRadius = 7;
    const fontLineHeightMultiplier = 1.15;
    const magnetismDistance = 5;

    // Base imprint shapes defaults
    const baseRectangle: ImprintBuilderObject = {
        id: 1,
        type: ImprintBuilderObjectType.RECTANGLE,
        width: 820*scaleFactor, height: 340*scaleFactor,
        x: 0, y: 0,
        zindex: 0,
        borderColor: "#000000FF", borderWeight: 1, borderStyle: ImprintBuilderBorderStyle.SOLID,
        fillColor: "#00000000",
        draggable: false,
        resizable: false,
        deletable: false,
        zindexable: false
    }
    const baseRound: ImprintBuilderObject = {
        id: 1,
        type: ImprintBuilderObjectType.ROUND,
        radius: 170*scaleFactor*1.64,
        x: 0, y: 0,
        zindex: 0,
        borderColor: "#000000FF", borderWeight: 1, borderStyle: ImprintBuilderBorderStyle.SOLID,
        fillColor: "#00000000",
        draggable: false,
        resizable: false,
        deletable: false,
        zindexable: false
    }

    useImperativeHandle(ref, () => ({
        async getImageData() {
            if (!drawingCanvasRef.current) return;
            redrawCanvas(true);

            const croppedCanvas = document.createElement('canvas');

            // Calculate the dimensions based on radius if available, or use default values
            const radius = imprintBuilderObjects.current[0].radius;
            const width = radius ? 2 * radius : (imprintBuilderObjects.current[0].width ?? 0);
            const height = radius ? 2 * radius : (imprintBuilderObjects.current[0].height ?? 0);

            croppedCanvas.width = width;
            croppedCanvas.height = height;

            const croppedCtx = croppedCanvas.getContext('2d');

            if (!croppedCtx) return;
            //croppedCtx.imageSmoothingEnabled = false;

            if (radius) {
                // If there's a radius, draw a circular crop
                croppedCtx.arc(width / 2, height / 2, radius, 0, Math.PI * 2);
                croppedCtx.clip();
                croppedCtx.drawImage(
                    drawingCanvasRef.current,
                    imprintBuilderObjects.current[0].x - 1 - radius,
                    imprintBuilderObjects.current[0].y - 1 - radius,
                    width+2,
                    height+2,
                    0, 0,
                    width,
                    height
                );
            } else {
                // If there's no radius, draw a rectangular crop
                croppedCtx.drawImage(
                    drawingCanvasRef.current,
                    imprintBuilderObjects.current[0].x-1,
                    imprintBuilderObjects.current[0].y-1,
                    width+2,
                    height+2,
                    0, 0,
                    width,
                    height
                );
            }

            return await resizeBase64Png(croppedCanvas.toDataURL("image/png"));

            // const baseShape = imprintBuilderObjects.current[0];
            //
            // const baseShapeRadius = baseShape.radius;
            // const baseShapeWidth = baseShapeRadius ? Math.round(2 * baseShapeRadius) : Math.round((baseShape.width ?? 0));
            // const baseShapeHeight = baseShapeRadius ? Math.round(2 * baseShapeRadius) : Math.round((baseShape.height ?? 0));
            // const baseShapeX = baseShapeRadius ? Math.round(baseShape.x - baseShapeRadius) : Math.round(baseShape.x);
            // const baseShapeY = baseShapeRadius ? Math.round(baseShape.y - baseShapeRadius) : Math.round(baseShape.y);
            //
            // const yamlObject = {
            //     shape: {
            //         width: Math.round(baseShapeWidth),
            //         height: Math.round(baseShapeHeight),
            //         stroke: baseShape.borderWeight ?? 1,
            //         radius: Math.round(baseShape.radius ?? 0),
            //         stroke_color: { rgba: hexWithAlphaToRGBA(baseShape.borderColor ?? "#000000FF") },
            //         fill_color: { rgba: hexWithAlphaToRGBA(baseShape.fillColor ?? "#00000000") }
            //     },
            //     elements: [] as K11GraphicsShapeWithContent[],
            //     resources: [] as { uri: string }[],
            //     with_info: false
            // };
            //
            // const resources: {uri: string, bytes: number[]}[] = [];
            //
            // imprintBuilderObjects.current.forEach((object, index) => {
            //     if (index === 0) return;
            //
            //     const objectElement: K11GraphicsShapeWithContent = {
            //         shape: {
            //             width: Math.round(object.width ?? 0),
            //             height: Math.round(object.height ?? 0),
            //             stroke: object.borderWeight ?? 0,
            //             radius: 0,
            //             stroke_color: { rgba: hexWithAlphaToRGBA(object.borderColor ?? "#000000FF") },
            //             fill_color: { rgba: hexWithAlphaToRGBA(object.fillColor ?? "#00000000") },
            //         },
            //         pos: {
            //             x: Math.round(object.x) - baseShapeX,
            //             y: Math.round(object.y) - baseShapeY,
            //             v_align: "None",
            //             h_align: "None"
            //         },
            //         alpha: null,
            //         content: (object.type !== ImprintBuilderObjectType.TEXTAREA ? [] : "")
            //     }
            //
            //     if (object.type === ImprintBuilderObjectType.TEXTAREA) {
            //         objectElement.shape.fill_color = { rgba: hexWithAlphaToRGBA("#00000000") };
            //         objectElement.content = [{
            //             text: object.text ?? "",
            //             font_name: resolveYamlFontString(object),
            //             font_color: { rgba: hexWithAlphaToRGBA(object.fillColor ?? "#00000000") },
            //             font_size: object.fontSize ?? 30,
            //             align: object.alignment,
            //             space_before: 3,
            //             space_after: Math.round((object.height ?? 0) - (object.fontSize ?? 0))
            //         }]
            //     } else if (object.src) {
            //         const resourcePath = object.src.src.replace("file:///", "");
            //         const fileNameWithExtension = resourcePath.match(/[^\\/]+$/);
            //
            //         if (fileNameWithExtension) {
            //             const resourceName = fileNameWithExtension[0].replace(/\.[^/.]+$/, "");
            //             const fileName = fileNameWithExtension[0].replace(/\.[^/.]+$/, "");
            //             const fileExtension = fileNameWithExtension[0].split('.').pop();
            //             const md5Hash = window.ipc.hashFilename(fileName).substring(0, 8);
            //
            //             // Read image data to give bytes
            //             const tmpResourceCanvas = document.createElement('canvas');
            //             tmpResourceCanvas.width = object.src.width;
            //             tmpResourceCanvas.height = object.src.height;
            //             const tmpResourceCtx = tmpResourceCanvas.getContext('2d');
            //
            //             if (tmpResourceCtx) {
            //                 tmpResourceCtx.drawImage(object.src, 0, 0, object.src.width, object.src.height);
            //                 const resourceDataURL = tmpResourceCanvas.toDataURL('image/png');
            //                 const base64 = atob(resourceDataURL.split(',')[1]);
            //                 const resourceBytes = new Uint8Array(base64.length);
            //
            //                 for (let i = 0; i < base64.length; i++) {
            //                     resourceBytes[i] = base64.charCodeAt(i);
            //                 }
            //
            //                 if (resourceName) {
            //                     resources.push({uri: `${md5Hash}.${fileExtension}`, bytes: Array.from(resourceBytes)});
            //                     objectElement.content = `${md5Hash}.${fileExtension}`;
            //                 }
            //             }
            //         }
            //     }
            //
            //     yamlObject.elements.push(objectElement);
            // });
            //
            // resources.forEach((resource: {uri: string, bytes: number[]}) => {
            //     yamlObject.resources.push({uri: resource.uri});
            // });
            //
            // let yamlFinalString = yaml.stringify(yamlObject);
            //
            // // Simply declare "content:" custom yaml tags
            // yamlFinalString = yamlFinalString.replaceAll(/content:(\s+-\s+text:)/g, 'content: !Text$1');
            // yamlFinalString = yamlFinalString.replaceAll(/content:(?=\s+[\w.]+)/g, 'content: !Image');
            //
            // setImprint(yamlFinalString);
            // setImprintYmlResources(resources);
            //
            // k11SdkService.buildImageFromYaml(yamlFinalString, resources);
            // spawnedK11processOnce.current = false;
        },
    }), [selectedObject]);

    // function hexWithAlphaToRGBA(hexWithAlpha: string): number[] {
    //     const hex = hexWithAlpha.slice(0, 7); // Remove the alpha channel part
    //     const alphaHex = hexWithAlpha.slice(7);
    //
    //     const r = parseInt(hex.slice(1, 3), 16);
    //     const g = parseInt(hex.slice(3, 5), 16);
    //     const b = parseInt(hex.slice(5, 7), 16);
    //     const alpha = parseInt(alphaHex, 16);
    //
    //     return [r, g, b, alpha];
    // }

    function rgbaToHexWithAlpha(rgba: number[]): string {
        const [r, g, b, a] = rgba;
        const hex = (r << 24) | (g << 16) | (b << 8) | a;
        return `#${hex.toString(16).padStart(8, '0')}`;
    }

    // Allows to compare values of ImprintObjects
    function areObjectsDifferent(objectA: Record<string, any>, objectB: Record<string, any>): boolean {
        for (const key of Object.keys(objectA)) {
            if (objectA[key] !== objectB[key]) {
                return true; // At least one value is different
            }
        }
        return false; // All values are the same
    }

    // Not duplicating the object on the canvas but cloning the javascript object so it uses another var reference (especially for history purposes)
    function cloneObject(object: ImprintBuilderObject): ImprintBuilderObject | null {
        if (object.type !== ImprintBuilderObjectType.IMAGE) {
            return structuredClone(object);
        } else {
            if (!object.src) {
                console.error("[ImprintBuilder] The image doesn't have a source!");
                return null;
            }

            const originalObjectSrc = object.src.cloneNode() as HTMLImageElement;

            object.src = undefined;

            const clonedObject = structuredClone(object);
            object.src = originalObjectSrc
            clonedObject.src = originalObjectSrc;

            return clonedObject;
        }
    }

    // Calculate center coordinates for an object
    function getCenteredCoordinates(canvasWidth: number, canvasHeight: number, objectWidth: number, objectHeight: number) {
        const x = (canvasWidth - objectWidth) / 2;
        const y = (canvasHeight - objectHeight) / 2;
        return { x, y };
    }

    // Calculate width and height of a TextArea object
    function getTextAreaMinimalDimensions(ctx: CanvasRenderingContext2D, textArea: ImprintBuilderObject): {width: number; height: number} {
        let calcWidth = 0;
        let calcHeight = 0;

        ctx.fillStyle = textArea.fillColor;
        if (textArea.borderColor) ctx.strokeStyle = textArea.borderColor;
        if (textArea.borderWeight) ctx.lineWidth = textArea.borderWeight;
        ctx.font = `${textArea.fontStyle} ${textArea.fontWeight} ${textArea.fontSize+"px" ?? "20px"} ${textArea.font}`;
        ctx.textAlign = textArea.alignment?.toLowerCase() as CanvasTextAlign ?? "left";

        textArea.text?.split("\n").forEach((textLine) => {
            if (textLine === "") textLine = 'M';

            const textMeasure = ctx.measureText(textLine);
            calcHeight += (textMeasure.fontBoundingBoxAscent + textMeasure.fontBoundingBoxDescent) * fontLineHeightMultiplier;
            if (textMeasure.width > calcWidth) calcWidth = textMeasure.width + 1;
        });

        return {width: calcWidth, height: calcHeight};
    }

    // Determines if a point is in a rectangle shaped box
    function isPointInRectangle(point: { x: number; y: number; }, rectangle: { x: number; width: any; y: number; height: any; }) {
        return point.x >= rectangle.x &&
            point.x <= (rectangle.x + rectangle.width) &&
            point.y >= rectangle.y &&
            point.y <= (rectangle.y + rectangle.height);
    }

    // Determines if a point is in a circle shaped box
    function isPointInCircle(point: { x: number; y: number; }, circle: { x: number; y: number; radius: number; }) {
        const dx = circle.x - point.x;
        const dy = circle.y - point.y;
        return (dx * dx + dy * dy) <= (circle.radius * circle.radius);
    }

    function checkObjectsOutsideShapeBounds() {
        const objectOutsideBounds = imprintBuilderObjects.current.find((object, i) => {
            if (i === 0) return undefined;

            return (imprintBuilderObjects.current[0].type === ImprintBuilderObjectType.RECTANGLE) ?
                !isPointInRectangle(
                    {x: object.x, y: object.y},
                    {
                        x: imprintBuilderObjects.current[0].x,
                        y: imprintBuilderObjects.current[0].y,
                        width: imprintBuilderObjects.current[0].width,
                        height: imprintBuilderObjects.current[0].height
                    }
                ) : !isPointInCircle({x: object.x, y: object.y},
                    {
                        x: imprintBuilderObjects.current[0].x,
                        y: imprintBuilderObjects.current[0].y,
                        radius: imprintBuilderObjects.current[0].radius ?? 0,
                    }
                );
        })

        setOutOfImprintBounds(typeof objectOutsideBounds !== "undefined");
    }

    // Returns which handle the mouse is hovering, null if none
    function isHoveringScaleHandle(point: { x: number; y: number; }): "top-left" | "top-right" | "bottom-left" | "bottom-right" | null {
        if (!selectedObject) return null;

        const selectionBoxWidth = (selectedObject.width) ? selectedObject.width+selectionBoxOffset : (selectedObject.radius) ? selectedObject.radius*2+selectionBoxOffset : undefined;
        const selectionBoxHeight = (selectedObject.height) ? selectedObject.height+selectionBoxOffset : (selectedObject.radius) ? selectedObject.radius*2+selectionBoxOffset : undefined;

        if (!selectionBoxWidth || !selectionBoxHeight) {
            console.error("[ImprintBuilder] Cannot get selection box with undefined width or height.");
            return null;
        }

        const topLeftHandleTouched = isPointInRectangle(point, {
            x: selectedObject.x - (selectionHandlesRadius+7),
            y: selectedObject.y - (selectionHandlesRadius+7),
            width: selectionHandlesRadius*2+7,
            height: selectionHandlesRadius*2+7
        });
        const topRightHandleTouched = isPointInRectangle(point, {
            x: selectedObject.x + selectionBoxWidth - (selectionHandlesRadius+7),
            y: selectedObject.y - (selectionHandlesRadius+7),
            width: selectionHandlesRadius*2+7,
            height: selectionHandlesRadius*2+7
        });
        const bottomLeftHandleTouched = isPointInRectangle(point, {
            x: selectedObject.x - (selectionHandlesRadius+7),
            y: selectedObject.y + selectionBoxHeight - (selectionHandlesRadius+7),
            width: selectionHandlesRadius*2+7,
            height: selectionHandlesRadius*2+7
        });
        const bottomRightHandleTouched = isPointInRectangle(point, {
            x: selectedObject.x + selectionBoxWidth - (selectionHandlesRadius+7),
            y: selectedObject.y + selectionBoxHeight - (selectionHandlesRadius+7),
            width: selectionHandlesRadius*2+7,
            height: selectionHandlesRadius*2+7
        });

        if (topLeftHandleTouched) return "top-left";
        else if (topRightHandleTouched) return "top-right";
        else if (bottomLeftHandleTouched) return "bottom-left";
        else if (bottomRightHandleTouched) return "bottom-right";
        else return null;
    }

    // Returns which handle the mouse is hovering, null if none
    function isHoveringBoxBorder(point: { x: number; y: number; }): "left" | "right" | "top" | "bottom" | null {
        if (!selectedObject) return null;

        const selectionBoxWidth = (selectedObject.width) ? selectedObject.width+selectionBoxOffset : (selectedObject.radius) ? selectedObject.radius*2+selectionBoxOffset : undefined;
        const selectionBoxHeight = (selectedObject.height) ? selectedObject.height+selectionBoxOffset : (selectedObject.radius) ? selectedObject.radius*2+selectionBoxOffset : undefined;

        if (!selectionBoxWidth || !selectionBoxHeight) {
            console.error("[ImprintBuilder] Cannot get selection box with undefined width or height.");
            return null;
        }

        const topBorderTouched = isPointInRectangle(point, {
            x: selectedObject.x,
            y: selectedObject.y - 9,
            width: selectionBoxWidth,
            height: 12
        });
        const leftBorderTouched = isPointInRectangle(point, {
            x: selectedObject.x - 9,
            y: selectedObject.y - 9,
            width: 12,
            height: selectionBoxHeight
        });
        const rightBorderTouched = isPointInRectangle(point, {
            x: selectedObject.x + (selectedObject.width ?? 0) - 3,
            y: selectedObject.y - 9,
            width: 12,
            height: selectionBoxHeight
        });
        const bottomBorderTouched = isPointInRectangle(point, {
            x: selectedObject.x,
            y: selectedObject.y + (selectedObject.height ?? 0) - 3,
            width: selectionBoxWidth,
            height: 12
        });

        if (topBorderTouched) return "top";
        else if (leftBorderTouched) return "left";
        else if (rightBorderTouched) return "right";
        else if (bottomBorderTouched) return "bottom";
        else return null;
    }

    // Updates z-index of the object
    function flipObjectToFrontOrBack(direction: -1 | 1) {
        if (!selectedObject) return;
        if (!selectedObject.zindexable) return;
        if (selectedObject.zindex + direction < 0) selectedObject.zindex = 0;

        const index = imprintBuilderObjects.current.indexOf(selectedObject);

        if (index === -1) return;

        registerHistoryObjectsOrder();

        if (direction > 0 && index < imprintBuilderObjects.current.length - 1) {
            // Move forward by swapping with the next element
            [imprintBuilderObjects.current[index], imprintBuilderObjects.current[index + 1]] = [imprintBuilderObjects.current[index + 1], imprintBuilderObjects.current[index]];
        } else if (direction < 0 && index > 0 && index-1 > 0) {
            // Move backward by swapping with the previous element
            [imprintBuilderObjects.current[index], imprintBuilderObjects.current[index - 1]] = [imprintBuilderObjects.current[index - 1], imprintBuilderObjects.current[index]];
        }

        imprintBuilderObjects.current.forEach((object, index) => {
            object.zindex = index;
        });

        redrawCanvas();
    }

    // Generates imprint builder objects from a YAML string
    function setImprintBuilderObjectsFromYaml(yamlString: string) {
        if (!imprintYmlModel) return;
        // Parse YAML content to JavaScript object
        const parsedYaml = yaml.parse(yamlString);
        console.log("Parsing YAML");
        console.log(parsedYaml);

        // Add the base shape to the objects
        setShape((parsedYaml.shape.radius !== null && parsedYaml.shape.radius !== 0) ? "round" : "rectangle");
        imprintBuilderObjects.current[0] = (parsedYaml.shape.radius !== null && parsedYaml.shape.radius !== 0) ? baseRound : baseRectangle;
        imprintBuilderObjects.current[0].borderColor = rgbaToHexWithAlpha(parsedYaml.shape.stroke_color.rgba);
        imprintBuilderObjects.current[0].borderWeight = parsedYaml.shape.stroke;
        imprintBuilderObjects.current[0].borderStyle = ImprintBuilderBorderStyle.SOLID;
        imprintBuilderObjects.current[0].fillColor = rgbaToHexWithAlpha(parsedYaml.shape.fill_color.rgba);

        const {baseShapeOffsetX, baseShapeOffsetY} = {
            baseShapeOffsetX: (imprintBuilderObjects.current[0].radius && imprintBuilderObjects.current[0].radius !== 0 ? (imprintBuilderObjects.current[0].x - imprintBuilderObjects.current[0].radius) : imprintBuilderObjects.current[0].x),
            baseShapeOffsetY: (imprintBuilderObjects.current[0].radius && imprintBuilderObjects.current[0].radius !== 0 ? (imprintBuilderObjects.current[0].y - imprintBuilderObjects.current[0].radius) : imprintBuilderObjects.current[0].y)
        }

        // Add the other elements (images and textareas)
        parsedYaml.elements.forEach((element: YamlElement, index: number) => {
            const newObject: ImprintBuilderObject = {
                id: imprintBuilderNextId.current + index,
                type: (typeof element.content === "string") ? ImprintBuilderObjectType.IMAGE : ImprintBuilderObjectType.TEXTAREA,
                x: element.pos.x + baseShapeOffsetX,
                y: element.pos.y + baseShapeOffsetY,
                zindex: index,
                width: element.shape.width,
                height: element.shape.height,
                borderColor: rgbaToHexWithAlpha(element.shape.stroke_color.rgba),
                borderWeight: element.shape.stroke,
                borderStyle: ImprintBuilderBorderStyle.SOLID,
                fillColor: rgbaToHexWithAlpha(element.shape.fill_color.rgba),
                draggable: false,
                resizable: false,
                deletable: true,
                zindexable: true
            }

            if (element.shape.radius && element.shape.radius !== 0) {
                newObject.radius = element.shape.radius;
            }

            if (element.content) {
                if (typeof element.content === "string") {
                    // It's an image
                    const image = new Image();
                    image.src = imprintYmlModel.resources.find(resource => resource.uri === element.content)?.src ?? "";

                    newObject.resizeMode = "keep-aspect";
                    newObject.src = image;
                } else {
                    const fontProperties = resolveFontPropertiesFromFontString(element.content[0].font_name);
                    // It's a textarea
                    newObject.resizeMode = "free";
                    newObject.text = element.content[0].text;
                    newObject.font = fontProperties.fontFamily;
                    newObject.fontSize = element.content[0].font_size;
                    newObject.fontStyle = fontProperties.fontStyle;
                    newObject.fontWeight = fontProperties.fontWeight;
                    newObject.alignment = element.content[0].align;
                    newObject.fillColor = element.content[0].font_color.rgba ? rgbaToHexWithAlpha(element.content[0].font_color.rgba) : "#000000FF";
                }
            }

            imprintBuilderObjects.current.push(newObject);
        });

        imprintBuilderNextId.current = parsedYaml.elements.length + 2;
        setYamlParsingCompleted(true);
    }

    // Draws every objects form objects storage into the canvas
    function drawObjects(ctx: CanvasRenderingContext2D, objects: ImprintBuilderObject[], forExport: boolean = false) {
        const zindexedObjects = objects.sort((objectA, objectB) => objectA.zindex - objectB.zindex);

        zindexedObjects.forEach(object => {
            if (!editedTextAreaObject || editedTextAreaObject && object.id !== editedTextAreaObject.id) {

                ctx.fillStyle = object.fillColor;
                if (object.borderColor) ctx.strokeStyle = object.borderColor;
                if (object.borderWeight) ctx.lineWidth = object.borderWeight;
                if (object.borderStyle === ImprintBuilderBorderStyle.DASHED) {
                    ctx.setLineDash([5 * (object.borderWeight ?? 1), 3 * (object.borderWeight ?? 1)]);
                }

                if (!forExport && (object.type === ImprintBuilderObjectType.ROUND || object.type === ImprintBuilderObjectType.RECTANGLE)) {
                    if (object.borderColor === "#00000000") {
                        ctx.strokeStyle = "rgba(0,0,0,0.2)";
                        ctx.setLineDash([5, 3]);
                        ctx.lineWidth = 1;
                    }
                }

                if (object.type !== ImprintBuilderObjectType.ROUND) {
                    if (!object.width || !object.height) return console.error(`[ImprintBuilder] Rectangle/Image ID ${object.id} is missing width or height properties`);

                    if (object.type !== ImprintBuilderObjectType.TEXTAREA) {
                        // +/- object.borderWeight to make the border grow inside instead of being centered
                        ctx.fillRect(
                            object.x + (object.borderWeight ?? 0) / 2,
                            object.y + (object.borderWeight ?? 0) / 2,
                            object.width - (object.borderWeight ?? 0) / 2 * 2,
                            object.height - (object.borderWeight ?? 0) / 2 * 2
                        );
                        ctx.strokeRect(
                            object.x + (object.borderWeight ?? 0) / 2,
                            object.y + (object.borderWeight ?? 0) / 2,
                            object.width - (object.borderWeight ?? 0) / 2 * 2,
                            object.height - (object.borderWeight ?? 0) / 2 * 2
                        );

                        if (object.borderStyle === ImprintBuilderBorderStyle.DOUBLE) {
                            ctx.strokeRect(
                                (object.x + (object.borderWeight ?? 0) / 2) + (3 + (object.borderWeight ?? 0)),
                                (object.y + (object.borderWeight ?? 0) / 2) + (3 + (object.borderWeight ?? 0)),
                                (object.width - (object.borderWeight ?? 0) / 2 * 2) - (3 + (object.borderWeight ?? 0))*2,
                                (object.height - (object.borderWeight ?? 0) / 2 * 2) - (3 + (object.borderWeight ?? 0))*2
                            );
                        }
                    } else {
                        ctx.setLineDash([0, 0]);
                        ctx.font = `${object.fontStyle} ${object.fontWeight} ${object.fontSize+"px" ?? "20px"} ${object.font ?? "Arial"}`;
                        ctx.textAlign = object.alignment?.toLowerCase() as CanvasTextAlign ?? "left";

                        // Splitting text every \n tokens to draw each text lines in new lines (new lines aren't supported by the fill/strokeText functions)
                        let cursorOffsetY = 0;

                        (object.text ?? "").split("\n").forEach((textLine) => {
                            if (!object.width || !object.height) return console.error(`[ImprintBuilder] TextArea ${object.id} is missing width or height properties`);
                            if (textLine === "") textLine = "<<MeasureTextHeight0x001>>";
                            const textMeasure = ctx.measureText(textLine);
                            if (textLine === "<<MeasureTextHeight0x001>>") textLine = "";
                            
                            if (ctx.textAlign === "left") {
                                ctx.fillText(textLine, object.x, object.y  + cursorOffsetY + (object.fontSize ?? 20));
                                ctx.strokeText(textLine, object.x, object.y  + cursorOffsetY + (object.fontSize ?? 20));
                            } else if (ctx.textAlign === "center") {
                                ctx.fillText(textLine, object.x + (object.width / 2), object.y  + cursorOffsetY + (object.fontSize ?? 20));
                                ctx.strokeText(textLine, object.x + (object.width / 2), object.y  + cursorOffsetY + (object.fontSize ?? 20));
                            } else if (ctx.textAlign === "right") {
                                ctx.fillText(textLine, object.x + object.width, object.y  + cursorOffsetY + (object.fontSize ?? 20));
                                ctx.strokeText(textLine, object.x + object.width, object.y  + cursorOffsetY + (object.fontSize ?? 20));
                            }

                            cursorOffsetY += (textMeasure.fontBoundingBoxAscent + textMeasure.fontBoundingBoxDescent) * fontLineHeightMultiplier;
                        });
                    }

                    if (object.src) {
                        ctx.drawImage(object.src, object.x, object.y, object.width, object.height);
                    }
                } else {
                    if (!object.radius) return console.error(`[ImprintBuilder] Round ID ${object.id} is missing radius property`);

                    ctx.beginPath();
                    // +/- object.borderWeight to make the border grow inside instead of being centered
                    ctx.arc(
                        object.x,
                        object.y,
                        object.radius - (object.borderWeight ?? 0)/2,
                        0,
                        2 * Math.PI
                    );
                    ctx.fill();
                    ctx.stroke();

                    if (object.borderStyle === ImprintBuilderBorderStyle.DOUBLE) {
                        ctx.beginPath();
                        // +/- object.borderWeight to make the border grow inside instead of being centered
                        ctx.arc(
                            object.x,
                            object.y,
                            object.radius - (3 + (object.borderWeight ?? 0)*2),
                            0,
                            2 * Math.PI
                        );
                        ctx.fill();
                        ctx.stroke();
                    }
                }
            }
        });
    }

    // Draws currently selected object (if any) selection box
    function drawSelectionBox(ctx: CanvasRenderingContext2D) {
        if (selectedObject) {
            const selectionBoxWidth = (selectedObject.width) ? selectedObject.width+6 : (selectedObject.radius) ? selectedObject.radius*2+selectionBoxOffset : undefined;
            const selectionBoxHeight = (selectedObject.height) ? selectedObject.height+6 : (selectedObject.radius) ? selectedObject.radius*2+selectionBoxOffset : undefined;

            if (!selectionBoxWidth || !selectionBoxHeight) return console.error("[ImprintBuilder] Cannot create selection box with undefined width or height.");

            ctx.lineWidth = 1;
            ctx.setLineDash([0, 0]);
            ctx.strokeStyle = "blue";
            ctx.strokeRect(
                selectedObject.x-selectionBoxOffset/2 - (selectedObject.radius ?? 0),
                selectedObject.y-selectionBoxOffset/2 - (selectedObject.radius ?? 0),
                selectionBoxWidth,
                selectionBoxHeight
            );

            // Draw selection box handles for scaling
            if (selectedObject.resizable) {
                ctx.lineWidth = 2;
                ctx.strokeStyle = "#2067A2FF";
                ctx.fillStyle = "blue";

                // Top-Left handle
                ctx.beginPath();
                ctx.arc(
                    selectedObject.x-selectionBoxOffset/2 - (selectedObject.radius ?? 0),
                    selectedObject.y-selectionBoxOffset/2 - (selectedObject.radius ?? 0),
                    selectionHandlesRadius,
                    0,
                    2 * Math.PI
                );
                ctx.fill();
                ctx.stroke();

                // Top-Right handle
                ctx.beginPath();
                ctx.arc(
                    selectedObject.x-selectionBoxOffset/2 - (selectedObject.radius ?? 0) + selectionBoxWidth,
                    selectedObject.y-selectionBoxOffset/2 - (selectedObject.radius ?? 0),
                    selectionHandlesRadius,
                    0,
                    2 * Math.PI
                );
                ctx.fill();
                ctx.stroke();

                // Bottom-Left handle
                ctx.beginPath();
                ctx.arc(
                    selectedObject.x-selectionBoxOffset/2 - (selectedObject.radius ?? 0),
                    selectedObject.y-selectionBoxOffset/2 - (selectedObject.radius ?? 0) + selectionBoxHeight,
                    selectionHandlesRadius,
                    0,
                    2 * Math.PI
                );
                ctx.fill();
                ctx.stroke();

                // Bottom-Right handle
                ctx.beginPath();
                ctx.arc(
                    selectedObject.x-selectionBoxOffset/2 - (selectedObject.radius ?? 0) + selectionBoxWidth,
                    selectedObject.y-selectionBoxOffset/2 - (selectedObject.radius ?? 0) + selectionBoxHeight,
                    selectionHandlesRadius,
                    0,
                    2 * Math.PI
                );
                ctx.fill();
                ctx.stroke();
            }
        }
    }

    function drawMagnetismLines(ctx: CanvasRenderingContext2D) {
        if (dragging && selectedObject && selectedObject.width && selectedObject.height) {
            ctx.lineWidth = 2;
            ctx.setLineDash([0, 0]);
            ctx.strokeStyle = "#fc6203";

            if (leftAlignLine) {
                ctx.beginPath();
                ctx.moveTo(selectedObject.x, selectedObject.y-1000);
                ctx.lineTo(selectedObject.x, selectedObject.y+1000);
                ctx.stroke();
            }

            if (rightAlignLine) {
                ctx.beginPath();
                ctx.moveTo(selectedObject.x + selectedObject.width, selectedObject.y-1000);
                ctx.lineTo(selectedObject.x + selectedObject.width, selectedObject.y+1000);
                ctx.stroke();
            }

            if (topAlignLine) {
                ctx.beginPath();
                ctx.moveTo(selectedObject.x-1000, selectedObject.y);
                ctx.lineTo(selectedObject.x+1000, selectedObject.y);
                ctx.stroke();
            }

            if (bottomAlignLine) {
                ctx.beginPath();
                ctx.moveTo(selectedObject.x-1000, selectedObject.y + selectedObject.height);
                ctx.lineTo(selectedObject.x+1000, selectedObject.y + selectedObject.height);
                ctx.stroke();
            }

            if (verticalCenterAlignLine) {
                ctx.beginPath();
                ctx.moveTo(selectedObject.x-1000, selectedObject.y + selectedObject.height / 2);
                ctx.lineTo(selectedObject.x+1000, selectedObject.y + selectedObject.height / 2);
                ctx.stroke();
            }

            if (horizontalCenterAlignLine) {
                ctx.beginPath();
                ctx.moveTo(selectedObject.x + selectedObject.width / 2, selectedObject.y-1000);
                ctx.lineTo(selectedObject.x + selectedObject.width / 2, selectedObject.y+1000);
                ctx.stroke();
            }
        }
    }

    // Shows debug drawings such as outlines of handles collision boxes
    // function drawDebug(ctx: CanvasRenderingContext2D) {
    //     if (!selectedObject) return;
    //
    //     // Debug real text rect
    //     if (selectedObject.type === ImprintBuilderObjectType.TEXTAREA && selectedObject.text && selectedObject.fontSize) {
    //         ctx.font = `${selectedObject.fontSize + "px" ?? "20px"} Arial`;
    //         ctx.textAlign = selectedObject.alignment?.toLowerCase() as CanvasTextAlign ?? "left";
    //
    //         const textMeasure = ctx.measureText(selectedObject.text);
    //         ctx.strokeStyle = "red";
    //         ctx.lineWidth = 1;
    //
    //         ctx.strokeRect(
    //             selectedObject.x,
    //             selectedObject.y,
    //             textMeasure.width,
    //             selectedObject.fontSize
    //         );
    //     }
    //
    //     // Debug handles collision box
    //     const selectionBoxWidth = (selectedObject.width) ? selectedObject.width+selectionBoxOffset : (selectedObject.radius) ? selectedObject.radius*2+selectionBoxOffset : undefined;
    //     const selectionBoxHeight = (selectedObject.height) ? selectedObject.height+selectionBoxOffset : (selectedObject.radius) ? selectedObject.radius*2+selectionBoxOffset : undefined;
    //
    //     if (!selectionBoxHeight || !selectionBoxWidth) return;
    //     ctx.strokeStyle = "green";
    //     ctx.lineWidth = 1;
    //     // TopLeft
    //     ctx.strokeRect(
    //         selectedObject.x - (selectionHandlesRadius+7),
    //         selectedObject.y - (selectionHandlesRadius+7),
    //         selectionHandlesRadius*2+7,
    //         selectionHandlesRadius*2+7
    //     );
    //     // TopRight
    //     ctx.strokeRect(
    //         selectedObject.x + selectionBoxWidth - (selectionHandlesRadius+7),
    //         selectedObject.y - (selectionHandlesRadius+7),
    //         selectionHandlesRadius*2+7,
    //         selectionHandlesRadius*2+7
    //     );
    //     // BottomLeft
    //     ctx.strokeRect(
    //         selectedObject.x - (selectionHandlesRadius+7),
    //         selectedObject.y + selectionBoxHeight - (selectionHandlesRadius+7),
    //         selectionHandlesRadius*2+7,
    //         selectionHandlesRadius*2+7
    //     );
    //     // BottomRight
    //     ctx.strokeRect(
    //         selectedObject.x + selectionBoxWidth - (selectionHandlesRadius+7),
    //         selectedObject.y + selectionBoxHeight - (selectionHandlesRadius+7),
    //         selectionHandlesRadius*2+7,
    //         selectionHandlesRadius*2+7
    //     );
    //
    //     // Debug selection border collisions box
    //     // Top
    //     ctx.strokeRect(
    //         selectedObject.x,
    //         selectedObject.y - 9,
    //         selectionBoxWidth,
    //         12
    //     );
    //     // Bottom
    //     ctx.strokeRect(
    //         selectedObject.x,
    //         selectedObject.y + (selectedObject.height ?? 0) - 3,
    //         selectionBoxWidth,
    //         12
    //     );
    //     // Left
    //     ctx.strokeRect(
    //         selectedObject.x - 9,
    //         selectedObject.y - 9,
    //         12,
    //         selectionBoxHeight
    //     );
    //     // Right
    //     ctx.strokeRect(
    //         selectedObject.x + (selectedObject.width ?? 0) - 3,
    //         selectedObject.y - 9,
    //         12,
    //         selectionBoxHeight
    //     );
    // }

    // Makes the whole canvas drawing with all objects stored and draws selection box if applicable
    function redrawCanvas(forExport = false) {
        if (!drawingCanvasRef.current) return;
        const canvas = drawingCanvasRef.current;

        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        // Clear the canvas first
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

        // Draw objects in order
        drawObjects(ctx, imprintBuilderObjects.current, forExport);

        if (!forExport) {
            // Draw selection box (if an object is selected)
            drawSelectionBox(ctx);

            // Draw object alignment lines
            drawMagnetismLines(ctx);

            //drawDebug(ctx);
        }

        // Reset ctx parameters with selected colors / line width in UI
        ctx.lineWidth = borderWeight;
        ctx.strokeStyle = borderColor;
        ctx.fillStyle = backgroundColor;
    }

    function imageInsertBrowser() { document.getElementById("imageInsertInput")?.click(); }
    function imageReplaceBrowser() { document.getElementById("imageReplaceInput")?.click(); }

    function handleImageInsertInput(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files[0]) {
            const reader = new FileReader();
            reader.onload = function(e) {
                if (e.target && e.target.result) {
                    insertImage(e.target.result as string);
                }
            }
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    function handleImageReplaceInput(e: React.ChangeEvent<HTMLInputElement>) {
        if (!selectedObject) return;

        if (e.target.files && e.target.files[0]) {
            const reader = new FileReader();
            reader.onload = function(e) {
                if (e.target && e.target.result) {
                    replaceImage(selectedObject, e.target.result as string);
                }
            }
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    function insertImage(src: string) {
        if (!drawingCanvasRef.current) return;
        const canvas = drawingCanvasRef.current;

        const image = new Image();

        image.onload = function() {
            const aspectRatio = image.width / image.height

            let scaledWidth = image.width;
            let scaledHeight = image.height;

            // Scale the image to fit in the canvas if it is bigger
            if (canvas.width / aspectRatio <= canvas.height) {
                // Width is the limiting factor
                scaledWidth = canvas.width * scaleFactor;
                scaledHeight = canvas.width / aspectRatio * scaleFactor;
            } else {
                // Height is the limiting factor
                scaledWidth = canvas.height * aspectRatio * scaleFactor;
                scaledHeight = canvas.height * scaleFactor;
            }

            const imageCenter = getCenteredCoordinates(canvas.width, canvas.height, scaledWidth, scaledHeight);

            imprintBuilderObjects.current.push({
                id: imprintBuilderNextId.current++,
                type: ImprintBuilderObjectType.IMAGE,
                width: scaledWidth, height: scaledHeight,
                x: imageCenter.x, y: imageCenter.y,
                zindex: imprintBuilderObjects.current.length,
                borderColor: "#00000000", borderWeight: 1, borderStyle: borderStyle,
                fillColor: "#00000000",
                src: image,
                draggable: true,
                resizable: true,
                resizeMode: "keep-aspect",
                deletable: true,
                zindexable: true
            });

            registerHistoryObjectRemoval(imprintBuilderObjects.current[imprintBuilderObjects.current.length-1]);
            setSelectedObject(imprintBuilderObjects.current[imprintBuilderObjects.current.length-1]);
            setShowSelectionPopup(true);
            setShowTextAreaPopup(false);

            redrawCanvas();
        };

        image.src = src;
    }

    function replaceImage(object: ImprintBuilderObject, newImageSrc: string) {
        if (!drawingCanvasRef.current) return;
        const canvas = drawingCanvasRef.current;

        const image = new Image();

        image.onload = function() {
            const aspectRatio = image.width / image.height

            let scaledWidth = image.width;
            let scaledHeight = image.height;

            // Scale the image to fit in the previous image dimensions
            if (!object.width || !object.height) return;
            if (object.width / aspectRatio <= object.height) {
                // Width is the limiting factor
                scaledWidth = object.width;
                scaledHeight = object.width / aspectRatio;
            } else {
                // Height is the limiting factor
                scaledWidth = object.height * aspectRatio;
                scaledHeight = object.height;
            }

            const imageCenter = getCenteredCoordinates(object.width, object.height, scaledWidth, scaledHeight);

            object.src = image;
            object.width = scaledWidth;
            object.height = scaledHeight;
            object.x = object.x + imageCenter.x;
            object.y = object.y + imageCenter.y;

            registerHistoryIfSelectedObjectUpdated();

            redrawCanvas();
        };

        image.src = newImageSrc;
    }

    function insertTextArea() {
        if (!drawingCanvasRef.current) return;
        const canvas = drawingCanvasRef.current;

        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        imprintBuilderObjects.current.push({
            id: imprintBuilderNextId.current++,
            type: ImprintBuilderObjectType.TEXTAREA,
            width: 100, height: fontSize + 5,
            x: 0, y: 0,
            zindex: imprintBuilderObjects.current.length,
            borderColor: "#00000000", borderWeight: 1,
            fillColor: "#000000FF",
            text: "Text",
            font: font,
            fontSize: fontSize,
            fontStyle: fontStyle,
            fontWeight: fontWeight,
            alignment: textAlign,
            draggable: true,
            resizable: true,
            resizeMode: "free",
            deletable: true,
            zindexable: true
        });

        const insertedTextArea = imprintBuilderObjects.current[imprintBuilderObjects.current.length-1];
        const {width, height} = getTextAreaMinimalDimensions(ctx, insertedTextArea);
        insertedTextArea.width = width;
        insertedTextArea.height = height;

        const textAreaCenter = getCenteredCoordinates(canvas.width, canvas.height, width, height);
        insertedTextArea.x = textAreaCenter.x;
        insertedTextArea.y = textAreaCenter.y;

        registerHistoryObjectRemoval(insertedTextArea);
        setSelectedObject(insertedTextArea);
        setShowTextAreaPopup(true);

        redrawCanvas();
    }

    function deleteSelectedObject() {
        if (!selectedObject) return;

        registerHistoryObjectAddition(selectedObject);

        imprintBuilderObjects.current.splice(imprintBuilderObjects.current.findIndex(obj => obj.id === selectedObject.id), 1);
        setSelectedObject(null);
        setShowSelectionPopup(false);
        setShowTextAreaPopup(false);

        redrawCanvas();
    }

    function undoOrRedo(event: KeyboardEvent | {ctrlKey: boolean, key: string}) {
        if (showEditingTextArea) return;

        if (event.ctrlKey && (event.key === 'z' || event.key === 'y')) {
            if (event.key === 'y' && imprintBuilderHistoryRedo.current.length === 0) return;
            if (event.key === 'z' && imprintBuilderHistoryUndo.current.length === 0) return;

            const currentHistoryEntry = (event.key === 'z') ? imprintBuilderHistoryUndo.current[imprintBuilderHistoryUndo.current.length - 1] : imprintBuilderHistoryRedo.current[imprintBuilderHistoryRedo.current.length - 1];

            if (currentHistoryEntry.type === ImprintBuilderHistoryEntryType.ADD) {
                imprintBuilderObjects.current.push(currentHistoryEntry.object as ImprintBuilderObject);
                setSelectedObject(imprintBuilderObjects.current[imprintBuilderObjects.current.length - 1]);

                if (event.key === 'z') {
                    imprintBuilderHistoryRedo.current.push({
                        type: ImprintBuilderHistoryEntryType.REMOVE,
                        object: currentHistoryEntry.object
                    });
                } else {
                    imprintBuilderHistoryUndo.current.push({
                        type: ImprintBuilderHistoryEntryType.REMOVE,
                        object: currentHistoryEntry.object
                    });
                }
            }

            if (currentHistoryEntry.type === ImprintBuilderHistoryEntryType.UPDATE) {
                const objectIndex = imprintBuilderObjects.current.findIndex((object) => object.id === (currentHistoryEntry.object as ImprintBuilderObject).id);

                const clonedObjectNewState = cloneObject(currentHistoryEntry.object as ImprintBuilderObject);
                if (!clonedObjectNewState) return console.error("[ImprintBuilder] Error while cloning object");

                const clonedObjectOriginalState = cloneObject(imprintBuilderObjects.current[objectIndex]);
                if (!clonedObjectOriginalState) return console.error("[ImprintBuilder] Error while cloning object");

                imprintBuilderObjects.current[objectIndex] = clonedObjectNewState;
                setSelectedObject(imprintBuilderObjects.current[objectIndex]);

                if (event.key === 'z') {
                    imprintBuilderHistoryRedo.current.push({
                        type: ImprintBuilderHistoryEntryType.UPDATE,
                        object: clonedObjectOriginalState
                    });
                } else {
                    imprintBuilderHistoryUndo.current.push({
                        type: ImprintBuilderHistoryEntryType.UPDATE,
                        object: clonedObjectOriginalState
                    });
                }
            }

            if (currentHistoryEntry.type === ImprintBuilderHistoryEntryType.REMOVE) {
                const objectIndex = imprintBuilderObjects.current.findIndex((object) => object.id === (currentHistoryEntry.object as ImprintBuilderObject).id);
                imprintBuilderObjects.current.splice(objectIndex, 1);
                setSelectedObject(null);
                setShowTextAreaPopup(false);
                setShowSelectionPopup(false);

                if (event.key === 'z') {
                    imprintBuilderHistoryRedo.current.push({
                        type: ImprintBuilderHistoryEntryType.ADD,
                        object: currentHistoryEntry.object
                    });
                } else {
                    imprintBuilderHistoryUndo.current.push({
                        type: ImprintBuilderHistoryEntryType.ADD,
                        object: currentHistoryEntry.object
                    });
                }
            }

            if (currentHistoryEntry.type === ImprintBuilderHistoryEntryType.ORDER) {
                const newObjectsOrder = currentHistoryEntry.object as number[];
                const beforeActionOrder = getObjectsOrder();

                if (event.key === 'z') {
                    newObjectsOrder.forEach((objectID, index) => {
                        const object = imprintBuilderObjects.current.find(object => object.id === objectID);
                        if (object) object.zindex = index;
                    });
                    imprintBuilderObjects.current.sort((objectA, objectB) => objectA.zindex - objectB.zindex);

                    imprintBuilderHistoryRedo.current.push({
                        type: ImprintBuilderHistoryEntryType.ORDER,
                        object: beforeActionOrder
                    });
                } else {
                    newObjectsOrder.forEach((objectID, index) => {
                        const object = imprintBuilderObjects.current.find(object => object.id === objectID);
                        if (object) object.zindex = index;
                    });
                    imprintBuilderObjects.current.sort((objectA, objectB) => objectA.zindex - objectB.zindex);

                    imprintBuilderHistoryUndo.current.push({
                        type: ImprintBuilderHistoryEntryType.ORDER,
                        object: beforeActionOrder
                    });
                }
            }

            if (event.key === 'z') imprintBuilderHistoryUndo.current.pop();
            else imprintBuilderHistoryRedo.current.pop();

            redrawCanvas();
        }
    }

    function altKeyListener(event: KeyboardEvent) {
        if (event.key === "Control" && allowMagnetism && event.type === "keydown") {
            setLeftAlignLine(false);
            setRightAlignLine(false);
            setTopAlignLine(false);
            setBottomAlignLine(false);
            setHorizontalCenterAlignLine(false);
            setVerticalCenterAlignLine(false);

            setAllowMagnetism(false);
            redrawCanvas();
        }

        if (event.key === "Control" && event.type === "keyup") setAllowMagnetism(true);
    }

    // Checks if the dragged object is close to other objects and help to align with these objects
    function applyMagnetismToSelectedObject() {
        if (!selectedObject) return;

        imprintBuilderObjects.current.forEach((object) => {
            if (object === selectedObject) return;

            // If the other object is the round base shape, I cheat by looking for the center of the whole canvas
            // I had a hard time trying to deal with the radius in my formulas...
            if (object.radius && selectedObject.width && selectedObject.height && drawingCanvasRef.current) {
                // Check horizontal proximity for horizontal center alignment
                if (Math.abs(selectedObject.x + selectedObject.width / 2 - drawingCanvasRef.current.width / 2) <= magnetismDistance) {
                    selectedObject.x = Math.round(drawingCanvasRef.current.width / 2 - selectedObject.width / 2);
                    setHorizontalCenterAlignLine(true);
                }

                // Check vertical proximity for vertical center alignment
                if (Math.abs(selectedObject.y + selectedObject.height / 2 - drawingCanvasRef.current.height / 2) <= magnetismDistance) {
                    selectedObject.y = Math.round(drawingCanvasRef.current.height / 2 - selectedObject.height / 2);
                    setVerticalCenterAlignLine(true);
                }
            }

            if (!object.width || !object.height || !selectedObject.width || !selectedObject.height) return;

            // Check horizontal proximity for left side to left side alignment
            if (Math.abs(selectedObject.x - object.x) <= magnetismDistance) {
                selectedObject.x = Math.round(object.x);
                setLeftAlignLine(true);
            }

            // Check horizontal proximity for left side to right side alignment
            else if (Math.abs(selectedObject.x - (object.x + object.width)) <= magnetismDistance) {
                selectedObject.x = Math.round(object.x + object.width);
                setLeftAlignLine(true);
            }

            // Check horizontal proximity for right side to right side alignment
            else if (Math.abs((selectedObject.x + selectedObject.width) - (object.x + object.width)) <= magnetismDistance) {
                selectedObject.x = Math.round((object.x + object.width) - selectedObject.width);
                setRightAlignLine(true);
            }

            // Check horizontal proximity for right side to left side alignment
            if (Math.abs((selectedObject.x + selectedObject.width) - object.x) <= magnetismDistance) {
                selectedObject.x = Math.round(object.x - selectedObject.width);
                setRightAlignLine(true);
            }

            // Check vertical proximity for top border to top border alignment
            if (Math.abs(selectedObject.y - object.y) <= magnetismDistance) {
                selectedObject.y = Math.round(object.y);
                setTopAlignLine(true);
            }

            // Check vertical proximity for top border to bottom border alignment
            else if (Math.abs(selectedObject.y - (object.y + object.height)) <= magnetismDistance) {
                selectedObject.y = Math.round(object.y + object.height);
                setTopAlignLine(true);
            }

            // Check vertical proximity for bottom border to top border alignment
            else if (Math.abs((selectedObject.y + selectedObject.height) - object.y) <= magnetismDistance) {
                selectedObject.y = Math.round(object.y - selectedObject.height);
                setBottomAlignLine(true);
            }

            // Check vertical proximity for bottom border to bottom border alignment
            else if (Math.abs((selectedObject.y + selectedObject.height) - (object.y + object.height)) <= magnetismDistance) {
                selectedObject.y = Math.round((object.y + object.height) - selectedObject.height);
                setBottomAlignLine(true);
            }

            // Check horizontal proximity for horizontal center alignment
            if (Math.abs(selectedObject.x + selectedObject.width / 2 - (object.x + object.width / 2)) <= magnetismDistance) {
                selectedObject.x = Math.round(object.x + object.width / 2 - selectedObject.width / 2);
                setHorizontalCenterAlignLine(true);
            }

            // Check vertical proximity for vertical center alignment
            if (Math.abs(selectedObject.y + selectedObject.height / 2 - (object.y + object.height / 2)) <= magnetismDistance) {
                selectedObject.y = Math.round(object.y + object.height / 2 - selectedObject.height / 2);
                setVerticalCenterAlignLine(true);
            }
        });
    }

    function handleMouseDown(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        if (!drawingCanvasRef.current) return;
        let hitObject = false;

        const rect = drawingCanvasRef.current.getBoundingClientRect();
        const point = { x: e.clientX - rect.left, y: e.clientY - rect.top };

        setShowSelectionPopup(false);
        setShowTextAreaPopup(false);

        // Mouse hit collision detection for selection scale handles and box borders
        if (selectedObject && selectedObject.resizable) {
            const hoveredScaleHandle = isHoveringScaleHandle(point);
            const hoveredBoxBorder = isHoveringBoxBorder(point);

            if (hoveredScaleHandle || hoveredBoxBorder) {
                return setResizing(true);
            }
        }

        // Mouse hit collision detection for selection
        const zindexedObjects = imprintBuilderObjects.current.sort((objectA, objectB) => objectA.zindex - objectB.zindex);

        zindexedObjects.forEach(object => {
            if ((object.type !== ImprintBuilderObjectType.ROUND) && isPointInRectangle(point, {x: object.x, y: object.y, width: object.width, height: object.height})) {
                setOffset({ x: e.clientX - object.x, y: e.clientY - object.y });
                setSelectedObject(object);
                hitObject = true;
            } else if (object.type === ImprintBuilderObjectType.ROUND && object.radius && isPointInCircle(point, {x: object.x, y: object.y, radius: object.radius})) {
                setOffset({ x: e.clientX - object.x, y: e.clientY - object.y });
                setSelectedObject(object);
                hitObject = true;
            }
        });

        if (!hitObject) {
            setSelectedObject(null);
        } else if (!editedTextAreaObject && selectedObject) {
            setSelectedObjectPreviousState(cloneObject(selectedObject));
            setDragging(true);
        }

        redrawCanvas();
    }

    // Double click detection that setup text area editing if the double click happened on a text area.
    function handleDoubleClick(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        if (!drawingCanvasRef.current) return;
        const ctx = drawingCanvasRef.current.getContext("2d");
        if (!ctx) return;

        setShowSelectionPopup(false);
        setShowTextAreaPopup(false);

        if (selectedObject && selectedObject.type === ImprintBuilderObjectType.TEXTAREA) {
            ctx.font = `${selectedObject.fontStyle} ${selectedObject.fontWeight} ${selectedObject.fontSize+"px" ?? "20px"} ${selectedObject.font}`;
            const textMeasure = ctx.measureText("M");

            if (editingTextAreaRef.current) editingTextAreaRef.current.value = selectedObject.text ?? "";
            setEditedTextAreaObject(selectedObject);
            setEditingTextAreaFont(`${selectedObject.fontStyle} ${selectedObject.fontWeight} ${selectedObject.fontSize+"px" ?? "20px"} ${selectedObject.font}`);
            setEditingTextAreaLineHeight((textMeasure.fontBoundingBoxAscent + textMeasure.fontBoundingBoxDescent) * fontLineHeightMultiplier);
            setEditingTextAreaColor(selectedObject.fillColor);
            setEditingTextAreaPosition({top: selectedObject.y, left: selectedObject.x});
            setEditingTextAreaSize({width: (selectedObject.width ?? 100), height: (selectedObject.height ?? 100)});
            setEditingTextAreaAlignment(selectedObject.alignment?.toLowerCase() ?? "left");
            setShowEditingTextArea(true);
            setTimeout(() => {
                if (editingTextAreaRef.current) editingTextAreaRef.current.focus();
            }, 50);
        }
    }

    function handleMouseMove(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        if (!drawingCanvasRef.current) return;

        const canvas = drawingCanvasRef.current;
        const ctx = canvas.getContext("2d");

        const rect = drawingCanvasRef.current.getBoundingClientRect();
        const point = { x: e.clientX - rect.left, y: e.clientY - rect.top };

        if (!ctx) return;

        checkObjectsOutsideShapeBounds();

        // Mouse hover collision detection for selection scale handles
        const hoveredScaleHandle = (!resizing) ? isHoveringScaleHandle(point) : currentResizeHandle;
        const hoveredBoxBorder = (!resizing) ? isHoveringBoxBorder(point) : currentResizeBorder;

        if (!hoveredBoxBorder && !hoveredScaleHandle) {
            let lastHitObject: ImprintBuilderObject | null = null;

            imprintBuilderObjects.current.forEach((object) => {
                let collCheck = false;

                if (!object.radius)
                    collCheck = isPointInRectangle(point, {
                        x: object.x,
                        y: object.y,
                        width: object.width,
                        height: object.height
                    });
                else
                    collCheck = isPointInCircle(point, {
                        x: object.x,
                        y: object.y,
                        radius: object.radius
                    });

                if (collCheck) {
                    lastHitObject = object;
                }
            });

            if (selectedObject && selectedObject.draggable && selectedObject === lastHitObject) {
                setHoverSelectableObject(false);
                setHoverSelectedAndDraggableObject(true);
            } else if (selectedObject && !selectedObject.draggable && selectedObject === lastHitObject) {
                setHoverSelectableObject(false);
                setHoverSelectedAndDraggableObject(false);
            } else  if (lastHitObject) {
                setHoverSelectableObject(true);
                setHoverSelectedAndDraggableObject(false);
            } else {
                setHoverSelectableObject(false);
                setHoverSelectedAndDraggableObject(false);
            }

        } else setHoverSelectableObject(false);

        let resizeHeightLimit = 5;
        let resizeWidthLimit = 5;

        if (selectedObject && selectedObject.type === ImprintBuilderObjectType.TEXTAREA) {
            const {width, height} = getTextAreaMinimalDimensions(ctx, selectedObject);
            resizeWidthLimit = Math.round(width);
            resizeHeightLimit = Math.round(height);
        }

        // Modify the cursor style when hovering a handle and resize if mouse is down
        if (selectedObject && (hoveredScaleHandle || hoveredBoxBorder) && selectedObject.resizable) {
            if (!resizing && hoveredScaleHandle) setCurrentResizeHandle(hoveredScaleHandle);
            else if (!resizing && !hoveredScaleHandle && hoveredBoxBorder) {
                setCurrentResizeHandle(null);
                if (selectedObject.resizeMode === "free") setCurrentResizeBorder(hoveredBoxBorder);
            }

            if (resizing && selectedObject.width && selectedObject.height && selectedObject.resizeMode) {
                const mouseX = e.clientX - canvas.getBoundingClientRect().left;
                const mouseY = e.clientY - canvas.getBoundingClientRect().top;

                const originalWidth = selectedObject.width;
                const originalHeight = selectedObject.height;
                const aspectRatio = originalWidth / originalHeight;

                // Object transforms when dragging scale handles (diagonal transform)
                if (hoveredScaleHandle === "bottom-right") {
                    const newWidth = mouseX - selectedObject.x;
                    const newHeight = selectedObject.resizeMode === "free" ? mouseY - selectedObject.y : newWidth / aspectRatio;

                    if (newWidth >= resizeWidthLimit && newHeight >= resizeHeightLimit) {
                        selectedObject.width = newWidth;
                        selectedObject.height = newHeight;
                    }
                } else if (hoveredScaleHandle === "bottom-left") {
                    const newWidth = selectedObject.x + selectedObject.width - mouseX;
                    const newHeight = selectedObject.resizeMode === "free" ? mouseY - selectedObject.y : newWidth / aspectRatio;

                    if (newWidth >= resizeWidthLimit && newHeight >= resizeHeightLimit) {
                        selectedObject.width = newWidth;
                        selectedObject.height = newHeight;
                        selectedObject.x = mouseX;
                    }
                } else if (hoveredScaleHandle === "top-left") {
                    const newWidth = selectedObject.x + selectedObject.width - mouseX;
                    const newHeight = selectedObject.resizeMode === "free" ? selectedObject.y + selectedObject.height - mouseY : newWidth / aspectRatio;
                    const newY = selectedObject.resizeMode === "free" ? mouseY : selectedObject.y + selectedObject.height - newHeight;

                    if (newWidth >= resizeWidthLimit && newHeight >= resizeHeightLimit) {
                        selectedObject.width = newWidth;
                        selectedObject.height = newHeight;
                        selectedObject.y = newY;
                        selectedObject.x = mouseX;
                    }
                } else if (hoveredScaleHandle === "top-right") {
                    const newWidth = mouseX - selectedObject.x;
                    const newHeight = selectedObject.resizeMode === "free" ? selectedObject.y + selectedObject.height - mouseY : newWidth / aspectRatio;
                    const newY = selectedObject.resizeMode === "free" ? mouseY : selectedObject.y + selectedObject.height - newHeight;

                    if (newWidth >= resizeWidthLimit && newHeight >= resizeHeightLimit) {
                        selectedObject.width = newWidth;
                        selectedObject.height = newHeight;
                        selectedObject.y = newY;
                    }
                }

                // Object transforms on borders, not available for objects with resizeMode "keep-aspect"
                if (!hoveredScaleHandle && hoveredBoxBorder) {
                    if (hoveredBoxBorder === "top") {
                        const newHeight = selectedObject.y + originalHeight - mouseY;

                        if (newHeight >= resizeHeightLimit) {
                            selectedObject.height = newHeight;
                            selectedObject.y = mouseY;
                        }
                    } else if (hoveredBoxBorder === "bottom") {
                        const newHeight = mouseY - selectedObject.y;

                        if (newHeight >= resizeHeightLimit) {
                            selectedObject.height = newHeight;
                        }
                    } else if (hoveredBoxBorder === "left") {
                        const newWidth = selectedObject.x + selectedObject.width - mouseX;

                        if (newWidth >= resizeWidthLimit) {
                            selectedObject.width = newWidth;
                            selectedObject.x = mouseX;
                        }
                    } else if (hoveredBoxBorder === "right") {
                        const newWidth = mouseX - selectedObject.x;

                        if (newWidth >= resizeWidthLimit) {
                            selectedObject.width = newWidth;
                        }
                    }
                }
            }
        } else if (!resizing) {
            setCurrentResizeHandle(null);
            setCurrentResizeBorder(null);
        }

        // Drag selected object
        if (dragging && selectedObject && selectedObject.draggable) {
            selectedObject.x = Math.round(e.clientX - offset.x);
            selectedObject.y = Math.round(e.clientY - offset.y);

            setLeftAlignLine(false);
            setRightAlignLine(false);
            setTopAlignLine(false);
            setBottomAlignLine(false);
            setHorizontalCenterAlignLine(false);
            setVerticalCenterAlignLine(false);

            if (allowMagnetism)
                applyMagnetismToSelectedObject();
        }

        redrawCanvas();
    }

    function handleMouseUp(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        setDragging(false);
        setResizing(false);

        registerHistoryIfSelectedObjectUpdated();
    }

    // Returns an array of objects ids in their draw order
    function getObjectsOrder(): number[] {
        const orderArray: number[] = [];

        imprintBuilderObjects.current.forEach((object) => {
            orderArray.push(object.id);
        });

        return orderArray;
    }

    // Register in history an object insert
    function registerHistoryObjectAddition(object: ImprintBuilderObject) {
        const clonedObject = cloneObject(object);
        if (!clonedObject) return console.error("[ImprintBuilder] Error while cloning object");

        imprintBuilderHistoryUndo.current.push({type: ImprintBuilderHistoryEntryType.ADD, object: clonedObject});
        imprintBuilderHistoryRedo.current = [];
    }

    // Register in history objects order change
    function registerHistoryObjectsOrder() {
        imprintBuilderHistoryUndo.current.push({type: ImprintBuilderHistoryEntryType.ORDER, object: getObjectsOrder()});
        imprintBuilderHistoryRedo.current = [];
    }

    // Register in history the selected object previous state and make selected object previous state the same as the current state to avoid a loop
    function registerHistoryIfSelectedObjectUpdated() {
        if (
            selectedObject &&
            selectedObjectPreviousState?.id === selectedObject.id &&
            areObjectsDifferent(selectedObject, selectedObjectPreviousState)
        ) {
            const clonedObject = cloneObject(selectedObjectPreviousState);
            if (!clonedObject) return console.error("[ImprintBuilder] Error while cloning object");

            imprintBuilderHistoryUndo.current.push({type: ImprintBuilderHistoryEntryType.UPDATE, object: clonedObject});
            imprintBuilderHistoryRedo.current = [];
            setSelectedObjectPreviousState(cloneObject(selectedObject));
        }
    }

    // Register in history the text area updated content
    function registerHistoryIfTextAreaContentUpdated(textArea: ImprintBuilderObject) {
        if (textArea.text !== textAreaPreviousContent) {
            const clonedObject = cloneObject(textArea);
            if (!clonedObject) return console.error("[ImprintBuilder] Error while cloning object");
            clonedObject.text = textAreaPreviousContent;

            imprintBuilderHistoryUndo.current.push({type: ImprintBuilderHistoryEntryType.UPDATE, object: clonedObject});
            imprintBuilderHistoryRedo.current = [];
        }
    }

    // Register in history the base shape previous state
    function registerHistoryBaseShapeChange() {
        const clonedObject = cloneObject(imprintBuilderObjects.current[0]);
        if (!clonedObject) return console.error("[ImprintBuilder] Error while cloning object");

        imprintBuilderHistoryUndo.current.push({type: ImprintBuilderHistoryEntryType.UPDATE, object: clonedObject});
        imprintBuilderHistoryRedo.current = [];
    }

    // Register in history an object delete
    function registerHistoryObjectRemoval(object: ImprintBuilderObject) {
        const clonedObject = cloneObject(object);
        if (!clonedObject) return console.error("[ImprintBuilder] Error while cloning object");

        imprintBuilderHistoryUndo.current.push({type: ImprintBuilderHistoryEntryType.REMOVE, object: clonedObject});
        imprintBuilderHistoryRedo.current = [];
    }

    // Returns if a font has bold and italic variants available
    function doesFontHasVariants(fontFamily: string): boolean {
        const fontIndex = fontsList.current.findIndex(font => fontFamily.includes(font));
        return fontsAvailableVariants.current[fontIndex].length > 1;
    }

    // Resolves a font string from an ImprintBuilderObject
    function resolveYamlFontString(object: ImprintBuilderObject): string {
        if (!object.font) return "";

        const fontFamily = fontsList.current.find(font => object.font && object.font.includes(font));
        let fontVariants = "";

        if (object.fontWeight === "normal" && object.fontStyle === "normal") fontVariants = "-Regular";
        if (object.fontWeight === "bold" && object.fontStyle === "normal") fontVariants = "-Bold";
        if (object.fontWeight === "normal" && object.fontStyle === "italic") fontVariants = "-Italic";
        if (object.fontWeight === "bold" && object.fontStyle === "italic") fontVariants = "-BoldItalic";

        return `${fontFamily}${fontVariants}`;
    }

    // Resolves font properties from a font string
    function resolveFontPropertiesFromFontString(fontString: string): {fontFamily: string, fontWeight: "normal" | "bold", fontStyle: "normal" | "italic"} {
        const fontIndex = fontsList.current.findIndex(font => fontString.includes(font));
        const fontVariants = fontsAvailableVariants.current[fontIndex];

        let fontWeight: "normal" | "bold" = "normal";
        let fontStyle: "normal" | "italic" = "normal";

        if (fontVariants.length > 1) {
            if (fontString.includes("-Bold") && fontString.includes("-Italic")) {
                fontWeight = "bold";
                fontStyle = "italic";
            } else if (fontString.includes("-Bold")) {
                fontWeight = "bold";
            } else if (fontString.includes("-Italic")) {
                fontStyle = "italic";
            }
        }

        return {fontFamily: fontsList.current[fontIndex], fontWeight, fontStyle};
    }

    // Lock/Unlock the selected object
    function toggleLocked() {
        if (!selectedObject) return;

        selectedObject.draggable = !selectedObject.draggable;
        selectedObject.resizable = !selectedObject.resizable;

        registerHistoryIfSelectedObjectUpdated();
        redrawCanvas();
    }

    // Initial effect
    useEffect(() => {
        if (!drawingCanvasRef.current) return;
        const canvas = drawingCanvasRef.current;

        if (!baseRectangle.width || !baseRectangle.height) return console.error("[ImprintBuilder] Issue with base rectangle default width & height");
        if (!baseRound.radius) return console.error("[ImprintBuilder] Issue with base round default radius");

        if (imprintBuilderObjects.current.length === 0) {

            const baseRectangleCenter = getCenteredCoordinates(canvas.width, canvas.height, baseRectangle.width, baseRectangle.height);
            baseRectangle.x = baseRectangleCenter.x;
            baseRectangle.y = baseRectangleCenter.y;

            const baseRoundCenter = getCenteredCoordinates(canvas.width, canvas.height , 0, 0);
            baseRound.x = baseRoundCenter.x;
            baseRound.y = baseRoundCenter.y;

            if (imprintYmlModel !== null) {
                setImprintBuilderObjectsFromYaml(imprintYmlModel.yaml);
            } else {
                imprintBuilderObjects.current.push(baseRectangle);
            }

        } else {setYamlParsingCompleted(true);}

        // FIXME: Awful workaround to force every imported fonts to load before using them in canvas
        const setFonts = async () => {
            fontsAvailableVariants.current.forEach((fontVariants, i) => {
                fontVariants.forEach(((fontVariant) => {
                    let fontVariantCSS = "";

                    if (fontVariant === "-Bold") fontVariantCSS = "; font-weight: bold;";
                    if (fontVariant === "-Italic") fontVariantCSS = "; font-style: italic;";
                    if (fontVariant === "-BoldItalic") fontVariantCSS = "; font-weight: bold; font-style: italic;";

                    const el = document.createElement("div")
                    el.innerText = "ABC";
                    el.setAttribute(
                        "style",
                        "position: absolute; top: -99999px; font-family: "
                        + fontsList.current[i] + fontVariantCSS
                    );
                    document.getElementsByTagName("body")[0].appendChild(el);
                    setTimeout(() => el.remove(), 500);
                }));
            })
        }
        setFonts();
        redrawCanvas();
    }, []);

    // Input listener effect
    useEffect(() => {
        document.addEventListener('keydown', undoOrRedo);
        document.addEventListener('keydown', altKeyListener);
        document.addEventListener('keyup', altKeyListener);

        return () => {
            document.removeEventListener('keydown', undoOrRedo);
            document.removeEventListener('keydown', altKeyListener);
            document.removeEventListener('keyup', altKeyListener);
        };
    }, [showEditingTextArea, selectedObject]);

    // When an object is selected, show its parameters in UI (colors..) and redraw canvas to draw selection box
    useEffect(() => {
        if (selectedObject) {
            setBackgroundColor(selectedObject.fillColor);
            if (selectedObject.borderColor) setBorderColor(selectedObject.borderColor);
            if (selectedObject.borderWeight) setBorderWeight(selectedObject.borderWeight);
            if (selectedObject.borderStyle) setBorderStyle(selectedObject.borderStyle);
            if (selectedObject.fontStyle) setFontStyle(selectedObject.fontStyle);
            if (selectedObject.fontWeight) setFontWeight(selectedObject.fontWeight);
            if (selectedObject.fontSize) setFontSize(selectedObject.fontSize);
            if (selectedObject.font) {
                setFont(selectedObject.font);
                setDisabledFontVariants(!doesFontHasVariants(selectedObject.font));
            }
            if (selectedObject.alignment) setTextAlign(selectedObject.alignment);

            setSelectedObjectPreviousState(cloneObject(selectedObject));

            if (selectedObject.type === ImprintBuilderObjectType.TEXTAREA) setDisabledBorderColor(true);
            else setDisabledBorderColor(false);

            if (selectedObject.type === ImprintBuilderObjectType.TEXTAREA) {
                setShowTextAreaPopup(true);
                setShowSelectionPopup(false);
            } else if (selectedObject.deletable) {
                setShowTextAreaPopup(false);
                setShowSelectionPopup(true);
            }
        }

        redrawCanvas();
    }, [selectedObject]);

    // When updating a design parameter (colors...), if an object is selected, attempt to apply it.
    useEffect(() => {
        if (!drawingCanvasRef.current) return;
        const canvas = drawingCanvasRef.current;

        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        if (selectedObject) {

            selectedObject.fillColor = backgroundColor;
            if (selectedObject.borderColor) selectedObject.borderColor = borderColor;
            if (selectedObject.borderWeight) selectedObject.borderWeight = borderWeight;
            if (selectedObject.borderStyle) selectedObject.borderStyle = borderStyle;
            if (selectedObject.fontSize) selectedObject.fontSize = fontSize;
            if (selectedObject.font) {
                selectedObject.font = font;
                const fontHasVariants = doesFontHasVariants(font);

                setDisabledFontVariants(!doesFontHasVariants(font));

                if (selectedObject.fontStyle) selectedObject.fontStyle = (fontHasVariants) ? fontStyle : "normal";
                if (selectedObject.fontWeight) selectedObject.fontWeight = (fontHasVariants) ? fontWeight : "normal";
            }
            if (selectedObject.alignment) selectedObject.alignment = textAlign;

            if (selectedObject.type === ImprintBuilderObjectType.TEXTAREA && selectedObject.width && selectedObject.height) {
                const {width, height} = getTextAreaMinimalDimensions(ctx, selectedObject);

                if (selectedObject.width < width) selectedObject.width = width;
                if (selectedObject.height < height) selectedObject.height = height;
            }

            registerHistoryIfSelectedObjectUpdated();
        }

        redrawCanvas();
    }, [backgroundColor, borderColor, borderWeight, borderStyle, font, fontWeight, fontStyle, fontSize, textAlign]);

    // When updating the base shape.
    useEffect(() => {
        if (!drawingCanvasRef.current) return;
        const canvas = drawingCanvasRef.current;

        if (imprintYmlModel !== null && !yamlParsingCompleted) return;

        const {type, borderColor, borderWeight, fillColor} = imprintBuilderObjects.current[0]; // Previous base properties
        const newBaseShape = (shape === "rectangle") ? baseRectangle : baseRound;

        if (type === newBaseShape.type) return;

        const newBaseShapeCenter = getCenteredCoordinates(canvas.width, canvas.height, newBaseShape.width ?? 0, newBaseShape.height ?? 0);

        newBaseShape.borderWeight = borderWeight;
        newBaseShape.borderColor = borderColor;
        newBaseShape.borderStyle = borderStyle;
        newBaseShape.fillColor = fillColor;
        newBaseShape.x = newBaseShapeCenter.x;
        newBaseShape.y = newBaseShapeCenter.y;

        registerHistoryBaseShapeChange();

        imprintBuilderObjects.current[0] = newBaseShape;

        if (selectedObject?.id === newBaseShape.id) setSelectedObject(imprintBuilderObjects.current[0]);

        checkObjectsOutsideShapeBounds();
        redrawCanvas();
    }, [shape]);

    // Place and show selection popup around the selected object if applicable when object is selected and not dragged or resized
    useEffect(() => {
        if (!drawingCanvasRef.current) return;
        setTooltipsPosition("bottom");

        if (!dragging) {
            setLeftAlignLine(false);
            setRightAlignLine(false);
            setTopAlignLine(false);
            setBottomAlignLine(false);
            setHorizontalCenterAlignLine(false);
            setVerticalCenterAlignLine(false);
        }

        if (selectionPopupRef.current && selectedObject && selectedObject.deletable && selectedObject.type !== ImprintBuilderObjectType.TEXTAREA && !dragging && !resizing) {
            if (selectedObject.width && selectedObject.height) {
                const popupWidth = selectionPopupRef.current.offsetWidth;
                const popupHeight = selectionPopupRef.current.offsetHeight;
                let popupLeft = selectedObject.x + selectedObject.width / 2 - popupWidth / 2;
                let popupTop = selectedObject.y + selectedObject.height + 10;

                // Check if the popup would go below the canvas bounds
                if (popupTop + popupHeight > drawingCanvasRef.current.height) {
                    popupTop = selectedObject.y - popupHeight - 10; // Position above the object
                    setTooltipsPosition("top");
                }

                // Ensure the popup stays within the canvas bounds horizontally
                if (popupLeft < 0) {
                    popupLeft = 0;
                } else if (popupLeft + popupWidth > drawingCanvasRef.current.width) {
                    popupLeft = drawingCanvasRef.current.width - popupWidth;
                }

                setSelectionPopupPosition({top: popupTop, left: popupLeft});
                setShowSelectionPopup(true);
            }
        } else if (textAreaPopupRef.current && selectedObject && selectedObject.type === ImprintBuilderObjectType.TEXTAREA && !dragging && !resizing) {
            if (selectedObject.width && selectedObject.height) {
                const popupWidth = textAreaPopupRef.current.offsetWidth;
                const popupHeight = textAreaPopupRef.current.offsetHeight;
                let popupLeft = selectedObject.x + selectedObject.width / 2 - popupWidth / 2;
                let popupTop = selectedObject.y + selectedObject.height + 15;

                // Check if the popup would go below the canvas bounds
                if (popupTop + popupHeight > drawingCanvasRef.current.height) {
                    popupTop = selectedObject.y - popupHeight - 15; // Position above the object
                    setTooltipsPosition("top");
                }

                // Ensure the popup stays within the canvas bounds horizontally
                if (popupLeft < 0) {
                    popupLeft = 0;
                } else if (popupLeft + popupWidth > drawingCanvasRef.current.width) {
                    popupLeft = drawingCanvasRef.current.width - popupWidth;
                }

                setTextAreaPopupPosition({top: popupTop, left: popupLeft});
                setShowTextAreaPopup(true);
            }
        }
    }, [selectedObject, dragging, resizing, showSelectionPopup, showTextAreaPopup])

    return(
        <div id={"imprint-builder"} style={{position: "relative", width: props.width+80, height: props.height}}>
            <div id={"imprint-builder-menu"} style={{
                display: "flex",
                flexDirection: "column",
                width: 60,
                height: "100%",
                backgroundColor: "#97AFB9",
                border: "solid #6d838d 1px",
                borderRadius: "10px",
                alignItems: "center",
                justifyItems: "center"
            }}>
                <Tooltip
                    placement={"left"} disableInteractive={true}
                    title={<span style={{fontSize: "17px"}}>{formatMessage("stepper.imprint.builder.shape.tooltip")}</span>}
                >
                    <div className={"imprint-builder-menu-item"}
                         ref={shapeMenuButton}
                         onClick={() => setOpenShapeMenu(true)}
                    >
                        <ImprintBuilderMenuShapeIcon/>
                    </div>
                </Tooltip>
                <Menu open={openShapeMenu}
                      onClose={() => setOpenShapeMenu(false)}
                      anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                      }}
                      anchorEl={shapeMenuButton.current}
                      sx={{"& .MuiPaper-root": {backgroundColor: "#97AFB9", color: "white"}}}
                >
                    <MenuItem onClick={() => setShape("rectangle")}>
                        <div style={{marginRight: "5px", visibility: (shape === "rectangle" ? "visible" : "hidden")}}><ImprintBuilderSelectedIcon/></div>
                        {formatMessage("stepper.imprint.builder.shape.rectangle")}
                    </MenuItem>
                    <MenuItem onClick={() => setShape("round")}>
                        <div style={{marginRight: "5px", visibility: (shape === "round" ? "visible" : "hidden")}}><ImprintBuilderSelectedIcon/></div>
                        {formatMessage("stepper.imprint.builder.shape.round")}
                    </MenuItem>
                </Menu>

                <Tooltip
                    placement={"left"} disableInteractive={true}
                    title={<span style={{fontSize: "17px"}}>{formatMessage("stepper.imprint.builder.background.tooltip")}</span>}
                >
                    <div className={"imprint-builder-menu-item-colorpick"}
                         ref={backgroundColorMenuButton}
                         onClick={() => setOpenBackgroundColorMenu(true)}
                    >
                        <ImprintBuilderMenuBackgroundColorIcon/>
                        <div className={"colorpick"} style={{
                            backgroundColor: backgroundColor,
                            backgroundImage: (backgroundColor === "#00000000" ? `url(${transparentBg})` : "none"),
                            backgroundSize: "cover"
                        }}></div>
                    </div>
                </Tooltip>
                <Menu open={openBackgroundColorMenu}
                      onClose={() => setOpenBackgroundColorMenu(false)}
                      anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                      }}
                      anchorEl={backgroundColorMenuButton.current}
                      sx={{"& .MuiPaper-root": {backgroundColor: "#97AFB9", color: "white"}}}
                >
                    <MenuItem onClick={() => setBackgroundColor("#00000000")}>
                        <div style={{marginRight: "5px", width: 20, height: 20, backgroundImage: `url(${transparentBg})`, backgroundSize: "contain", borderRadius: 1000}}>
                            <div style={{margin: "-1px 4px 0px 4px", filter: "invert(1)", visibility: (backgroundColor === "#00000000" ? "visible" : "hidden")}}><ImprintBuilderSelectedIcon/></div>
                        </div>
                        {formatMessage("stepper.imprint.builder.color.transparent")}
                    </MenuItem>
                    <MenuItem onClick={() => setBackgroundColor("#000000FF")}>
                        <div style={{marginRight: "5px", width: 20, height: 20, backgroundColor: "#000000FF", borderRadius: 1000}}>
                            <div style={{margin: "-1px 4px 0px 4px", visibility: (backgroundColor === "#000000FF" ? "visible" : "hidden")}}><ImprintBuilderSelectedIcon/></div>
                        </div>
                        {formatMessage("stepper.imprint.builder.color.black")}
                    </MenuItem>
                    <MenuItem onClick={() => setBackgroundColor("#FFFFFFFF")}>
                        <div style={{marginRight: "5px", width: 20, height: 20, backgroundColor: "#FFFFFFFF", borderRadius: 1000}}>
                            <div style={{margin: "-1px 4px 0px 4px", visibility: (backgroundColor === "#FFFFFFFF" ? "visible" : "hidden")}}><ImprintBuilderSelectedIcon/></div>
                        </div>
                        {formatMessage("stepper.imprint.builder.color.white")}
                    </MenuItem>
                    <MenuItem onClick={() => setBackgroundColor("#2196F3FF")}>
                        <div style={{marginRight: "5px", width: 20, height: 20, backgroundColor: "#2196F3", borderRadius: 1000}}>
                            <div style={{margin: "-1px 4px 0px 4px", visibility: (backgroundColor === "#2196F3" ? "visible" : "hidden")}}><ImprintBuilderSelectedIcon/></div>
                        </div>
                        {formatMessage("stepper.imprint.builder.color.blue")}
                    </MenuItem>
                    <MenuItem onClick={() => setBackgroundColor("#FA0A0AFF")}>
                        <div style={{marginRight: "5px", width: 20, height: 20, backgroundColor: "#FA0A0A", borderRadius: 1000}}>
                            <div style={{margin: "-1px 4px 0px 4px", visibility: (backgroundColor === "#FA0A0A" ? "visible" : "hidden")}}><ImprintBuilderSelectedIcon/></div>
                        </div>
                        {formatMessage("stepper.imprint.builder.color.red")}
                    </MenuItem>
                    <MenuItem onClick={() => setBackgroundColor("#FFC107FF")}>
                        <div style={{marginRight: "5px", width: 20, height: 20, backgroundColor: "#FFC107", borderRadius: 1000}}>
                            <div style={{margin: "-1px 4px 0px 4px", visibility: (backgroundColor === "#FFC107" ? "visible" : "hidden")}}><ImprintBuilderSelectedIcon/></div>
                        </div>
                        {formatMessage("stepper.imprint.builder.color.yellow")}
                    </MenuItem>
                    <MenuItem onClick={() => setBackgroundColor("#00C144FF")}>
                        <div style={{marginRight: "5px", width: 20, height: 20, backgroundColor: "#00C144", borderRadius: 1000}}>
                            <div style={{margin: "-1px 4px 0px 4px", visibility: (backgroundColor === "#00C144" ? "visible" : "hidden")}}><ImprintBuilderSelectedIcon/></div>
                        </div>
                        {formatMessage("stepper.imprint.builder.color.green")}
                    </MenuItem>
                </Menu>

                <Tooltip
                    placement={"left"} disableInteractive={true}
                    title={<span style={{fontSize: "17px"}}>{formatMessage("stepper.imprint.builder.border.tooltip")}</span>}
                >
                    <div className={"imprint-builder-menu-item-colorpick"+(disabledBorderColor ? " disabled" : "")}
                         ref={borderColorMenuButton}
                         onClick={() => {
                             if (!disabledBorderColor) setOpenBorderColorMenu(true);
                         }}
                    >
                        <ImprintBuilderMenuBorderColorIcon/>
                        <div className={"colorpick"} style={{
                            backgroundColor: borderColor,
                            backgroundImage: (borderColor === "#00000000" ? `url(${transparentBg})` : "none"),
                            backgroundSize: "cover"
                        }}></div>
                    </div>
                </Tooltip>
                <Menu open={openBorderColorMenu}
                      onClose={() => setOpenBorderColorMenu(false)}
                      anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                      }}
                      anchorEl={borderColorMenuButton.current}
                      sx={{"& .MuiPaper-root": {backgroundColor: "#97AFB9", color: "white"}}}
                >
                    <MenuItem onClick={() => setBorderColor("#00000000")}>
                        <div style={{marginRight: "5px", width: 20, height: 20, backgroundImage: `url(${transparentBg})`, backgroundSize: "contain", borderRadius: 1000}}>
                            <div style={{margin: "-1px 4px 0px 4px", filter: "invert(1)", visibility: (borderColor === "#00000000" ? "visible" : "hidden")}}><ImprintBuilderSelectedIcon/></div>
                        </div>
                        {formatMessage("stepper.imprint.builder.color.transparent")}
                    </MenuItem>
                    <MenuItem onClick={() => setBorderColor("#000000FF")}>
                        <div style={{marginRight: "5px", width: 20, height: 20, backgroundColor: "#000000FF", borderRadius: 1000}}>
                            <div style={{margin: "-1px 4px 0px 4px", visibility: (borderColor === "#000000FF" ? "visible" : "hidden")}}><ImprintBuilderSelectedIcon/></div>
                        </div>
                        {formatMessage("stepper.imprint.builder.color.black")}
                    </MenuItem>
                    <MenuItem onClick={() => setBorderColor("#FFFFFFFF")}>
                        <div style={{marginRight: "5px", width: 20, height: 20, backgroundColor: "#FFFFFFFF", borderRadius: 1000}}>
                            <div style={{margin: "-1px 4px 0px 4px", visibility: (borderColor === "#FFFFFFFF" ? "visible" : "hidden")}}><ImprintBuilderSelectedIcon/></div>
                        </div>
                        {formatMessage("stepper.imprint.builder.color.white")}
                    </MenuItem>
                    <MenuItem onClick={() => setBorderColor("#2196F3FF")}>
                        <div style={{marginRight: "5px", width: 20, height: 20, backgroundColor: "#2196F3FF", borderRadius: 1000}}>
                            <div style={{margin: "-1px 4px 0px 4px", visibility: (borderColor === "#2196F3" ? "visible" : "hidden")}}><ImprintBuilderSelectedIcon/></div>
                        </div>
                        {formatMessage("stepper.imprint.builder.color.blue")}
                    </MenuItem>
                    <MenuItem onClick={() => setBorderColor("#FA0A0AFF")}>
                        <div style={{marginRight: "5px", width: 20, height: 20, backgroundColor: "#FA0A0AFF", borderRadius: 1000}}>
                            <div style={{margin: "-1px 4px 0px 4px", visibility: (borderColor === "#FA0A0A" ? "visible" : "hidden")}}><ImprintBuilderSelectedIcon/></div>
                        </div>
                        {formatMessage("stepper.imprint.builder.color.red")}
                    </MenuItem>
                    <MenuItem onClick={() => setBorderColor("#FFC107FF")}>
                        <div style={{marginRight: "5px", width: 20, height: 20, backgroundColor: "#FFC107FF", borderRadius: 1000}}>
                            <div style={{margin: "-1px 4px 0px 4px", visibility: (borderColor === "#FFC107" ? "visible" : "hidden")}}><ImprintBuilderSelectedIcon/></div>
                        </div>
                        {formatMessage("stepper.imprint.builder.color.yellow")}
                    </MenuItem>
                    <MenuItem onClick={() => setBorderColor("#00C144FF")}>
                        <div style={{marginRight: "5px", width: 20, height: 20, backgroundColor: "#00C144FF", borderRadius: 1000}}>
                            <div style={{margin: "-1px 4px 0px 4px", visibility: (borderColor === "#00C144" ? "visible" : "hidden")}}><ImprintBuilderSelectedIcon/></div>
                        </div>
                        {formatMessage("stepper.imprint.builder.color.green")}
                    </MenuItem>
                </Menu>

                <Tooltip
                    placement={"left"} disableInteractive={true}
                    title={<span style={{fontSize: "17px"}}>{formatMessage("stepper.imprint.builder.lineWeight.tooltip")}</span>}
                >
                    <div className={"imprint-builder-menu-item"}
                         ref={borderWeightMenuButton}
                         onClick={() => setOpenBorderWeightMenu(true)}
                    >
                        <ImprintBuilderMenuLineWeightIcon/>
                    </div>
                </Tooltip>
                <Menu open={openBorderWeightMenu}
                      onClose={() => setOpenBorderWeightMenu(false)}
                      anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                      }}
                      anchorEl={borderWeightMenuButton.current}
                      sx={{"& .MuiPaper-root": {backgroundColor: "#97AFB9", color: "white"}}}
                >
                    <MenuItem onClick={() => setBorderWeight(1)}>
                        <div style={{marginRight: "5px", visibility: (borderWeight === 1 ? "visible" : "hidden")}}><ImprintBuilderSelectedIcon/></div>
                        1
                    </MenuItem>
                    <MenuItem onClick={() => setBorderWeight(2)}>
                        <div style={{marginRight: "5px", visibility: (borderWeight === 2 ? "visible" : "hidden")}}><ImprintBuilderSelectedIcon/></div>
                        2
                    </MenuItem>
                    <MenuItem onClick={() => setBorderWeight(3)}>
                        <div style={{marginRight: "5px", visibility: (borderWeight === 3 ? "visible" : "hidden")}}><ImprintBuilderSelectedIcon/></div>
                        3
                    </MenuItem>
                    <MenuItem onClick={() => setBorderWeight(4)}>
                        <div style={{marginRight: "5px", visibility: (borderWeight === 4 ? "visible" : "hidden")}}><ImprintBuilderSelectedIcon/></div>
                        4
                    </MenuItem>
                    <MenuItem onClick={() => setBorderWeight(5)}>
                        <div style={{marginRight: "5px", visibility: (borderWeight === 5 ? "visible" : "hidden")}}><ImprintBuilderSelectedIcon/></div>
                        5
                    </MenuItem>
                </Menu>

                <Tooltip
                    placement={"left"} disableInteractive={true}
                    title={<span style={{fontSize: "17px"}}>{formatMessage("stepper.imprint.builder.borderStyle.tooltip")}</span>}
                >
                    <div className={"imprint-builder-menu-item"}
                         ref={borderStyleMenuButton}
                         onClick={() => setOpenBorderStyleMenu(true)}
                    >
                        <BorderStyleIcon/>
                    </div>
                </Tooltip>
                <Menu open={openBorderStyleMenu}
                      onClose={() => setOpenBorderStyleMenu(false)}
                      anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                      }}
                      anchorEl={borderStyleMenuButton.current}
                      sx={{"& .MuiPaper-root": {backgroundColor: "#97AFB9", color: "white"}}}
                >
                    <MenuItem onClick={() => setBorderStyle(ImprintBuilderBorderStyle.SOLID)}>
                        <div style={{marginRight: "5px", visibility: (borderStyle === ImprintBuilderBorderStyle.SOLID ? "visible" : "hidden")}}><ImprintBuilderSelectedIcon/></div>
                        {formatMessage("stepper.imprint.builder.borderStyle.solid")}
                    </MenuItem>
                    {/*<MenuItem onClick={() => setBorderStyle(ImprintBuilderBorderStyle.DASHED)}>*/}
                    {/*    <div style={{marginRight: "5px", visibility: (borderStyle === ImprintBuilderBorderStyle.DASHED ? "visible" : "hidden")}}><ImprintBuilderSelectedIcon/></div>*/}
                    {/*    {formatMessage("stepper.imprint.builder.borderStyle.dashed")}*/}
                    {/*</MenuItem>*/}
                    {/*<MenuItem onClick={() => setBorderStyle(ImprintBuilderBorderStyle.DOUBLE)}>*/}
                    {/*    <div style={{marginRight: "5px", visibility: (borderStyle === ImprintBuilderBorderStyle.DOUBLE ? "visible" : "hidden")}}><ImprintBuilderSelectedIcon/></div>*/}
                    {/*    {formatMessage("stepper.imprint.builder.borderStyle.double")}*/}
                    {/*</MenuItem>*/}
                </Menu>

                <Tooltip
                    placement={"left"} disableInteractive={true}
                    title={<span style={{fontSize: "17px"}}>{formatMessage("stepper.imprint.builder.textArea.tooltip")}</span>}
                >
                    <div className={"imprint-builder-menu-item"} onClick={() => insertTextArea()}>
                        <ImprintBuilderMenuTextAreaIcon/>
                    </div>
                </Tooltip>

                <Tooltip
                    placement={"left"} disableInteractive={true}
                    title={<span style={{fontSize: "17px"}}>{formatMessage("stepper.imprint.builder.image.tooltip")}</span>}
                >
                    <div className={"imprint-builder-menu-item"} onClick={() => {imageInsertBrowser()}}>
                        <ImprintBuilderMenuImageIcon/>
                    </div>
                </Tooltip>
            </div>

            <div style={{
                ...props.style,
                width: props.width+1,
                height: props.height+1,
                position: "absolute",
                top: 0,
                left: 80
            }}>
                <canvas
                    width={props.width}
                    height={props.height}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        cursor:
                            (currentResizeHandle === "top-left") ? "nw-resize" :
                            (currentResizeHandle === "top-right") ? "ne-resize" :
                            (currentResizeHandle === "bottom-left") ? "sw-resize" :
                            (currentResizeHandle === "bottom-right") ? "se-resize" :
                            (currentResizeBorder === "top" || currentResizeBorder === "bottom") ? "ns-resize" :
                            (currentResizeBorder === "left" || currentResizeBorder === "right") ? "ew-resize" :
                            (hoverSelectableObject) ? "pointer" :
                            (hoverSelectedAndDraggableObject) ? "move" : "initial"
                    }}
                    ref={drawingCanvasRef}
                    onMouseDown={(e) => handleMouseDown(e)}
                    onMouseUp={(e) => handleMouseUp(e)}
                    onMouseOut={(e) => handleMouseUp(e)}
                    onMouseMove={(e) => handleMouseMove(e)}
                    onDoubleClick={(e) => handleDoubleClick(e)}
                />
                <span style={{
                    position: "absolute",
                    top: props.height + 10,
                    left: 0
                }}>{formatMessage("stepper.imprint.builder.altKeyTip", {alt: "Ctrl"})}</span>
                {outOfImprintBounds && <span style={{
                    position: "absolute",
                    color: "red",
                    top: props.height + 30,
                    left: 0
                }}>{formatMessage("stepper.imprint.builder.outOfBoundsWarn")}</span>}
                <div
                    ref={selectionPopupRef}
                    style={{
                        position: "absolute",
                        padding: 3,
                        backgroundColor: "#97AFB9",
                        border: "solid #6d838d 1px",
                        borderRadius: "3px",
                        top: selectionPopupPosition.top,
                        left: selectionPopupPosition.left,
                        display: (showSelectionPopup) ? "flex" : "none",
                        alignItems: "center"
                    }}
                >
                    <Tooltip
                        placement={tooltipsPosition as "top" | "bottom"} disableInteractive={true} arrow={true}
                        title={<span style={{fontSize: "12px"}}>{formatMessage("menu.edit.replaceImage")}</span>}
                    >
                        <ImageIcon
                            className={"popup-button"}
                            onClick={() => imageReplaceBrowser()}
                        />
                    </Tooltip>
                    <div className={"separator"}></div>
                    <Tooltip
                        placement={tooltipsPosition as "top" | "bottom"} disableInteractive={true} arrow={true}
                        title={<span style={{fontSize: "12px"}}>{formatMessage("menu.edit.lockPos")}</span>}
                    >
                        { (selectedObject?.draggable && selectedObject?.resizable) ?
                            <LockOpenIcon
                                className={"popup-button"}
                                onClick={() => toggleLocked()}
                            /> :
                            <LockIcon
                                className={"popup-button"}
                                onClick={() => toggleLocked()}
                            />
                        }
                    </Tooltip>
                    <Tooltip
                        placement={tooltipsPosition as "top" | "bottom"} disableInteractive={true} arrow={true}
                        title={<span style={{fontSize: "12px"}}>{formatMessage("menu.edit.flipToFront")}</span>}
                    >
                        <FlipToFrontIcon
                            className={"popup-button"}
                            onClick={() => flipObjectToFrontOrBack(1)}
                        />
                    </Tooltip>
                    <Tooltip
                        placement={tooltipsPosition as "top" | "bottom"} disableInteractive={true} arrow={true}
                        title={<span style={{fontSize: "12px"}}>{formatMessage("menu.edit.flipToBack")}</span>}
                    >
                        <FlipToBackIcon
                            className={"popup-button"}
                            onClick={() => flipObjectToFrontOrBack(-1)}
                        />
                    </Tooltip>
                    <Tooltip
                        placement={tooltipsPosition as "top" | "bottom"} disableInteractive={true} arrow={true}
                        title={<span style={{fontSize: "12px"}}>{formatMessage("menu.edit.delete")}</span>}
                    >
                        <DeleteIcon
                            className={"popup-button"}
                            onClick={() => deleteSelectedObject()}
                        />
                    </Tooltip>
                </div>
                <div
                    ref={undoRedoPopupRef}
                    style={{
                        position: "absolute",
                        padding: 3,
                        backgroundColor: "#97AFB9",
                        border: "solid #6d838d 1px",
                        borderRadius: "3px",
                        bottom: 5,
                        right: 5,
                        display: (imprintBuilderHistoryUndo.current.length > 0 || imprintBuilderHistoryRedo.current.length > 0) ? "flex" : "none",
                        alignItems: "center"
                    }}
                >
                    <Tooltip
                        placement={"bottom"} disableInteractive={true} arrow={true} componentsProps={{tooltip: {sx: {textAlign: "center"}}}}
                        title={<span style={{fontSize: "12px"}}>{formatMessage("menu.edit.undo")}<br/>(Ctrl+Z)</span>}
                    >
                        <UndoIcon
                            className={`popup-button${(imprintBuilderHistoryUndo.current.length < 1) ? "-disabled" : ""}`}
                            onClick={() => undoOrRedo({key: 'z', ctrlKey: true})}
                        />
                    </Tooltip>
                    <div style={{width: "5px"}}></div>
                    <Tooltip
                        placement={"bottom"} disableInteractive={true} arrow={true} componentsProps={{tooltip: {sx: {textAlign: "center"}}}}
                        title={<span style={{fontSize: "12px"}}>{formatMessage("menu.edit.redo")}<br/>(Ctrl+Y)</span>}
                    >
                        <RedoIcon
                            className={`popup-button${(imprintBuilderHistoryRedo.current.length < 1) ? "-disabled" : ""}`}
                            onClick={() => undoOrRedo({key: 'y', ctrlKey: true})}
                        />
                    </Tooltip>
                </div>
                <div
                    ref={textAreaPopupRef}
                    style={{
                        position: "absolute",
                        padding: 3,
                        backgroundColor: "#97AFB9",
                        border: "solid #6d838d 1px",
                        borderRadius: "3px",
                        top: textAreaPopupPosition.top,
                        left: textAreaPopupPosition.left,
                        display: (showTextAreaPopup) ? "flex" : "none",
                        alignItems: "center"
                    }}
                >
                    <Tooltip
                        placement={tooltipsPosition as "top" | "bottom"} disableInteractive={true} arrow={true}
                        title={<span style={{fontSize: "12px"}}>{formatMessage("menu.edit.font")}</span>}
                    >
                        <select
                            className={"fontInput"}
                            value={font}
                            onChange={(e) => {
                                setFont(e.target.value);
                            }}
                        >
                            {
                                fontsList.current.map((fontName) => (
                                    <option key={fontName} value={fontName}>{fontName}</option>
                                ))
                            }
                        </select>
                    </Tooltip>
                    <Tooltip
                        placement={tooltipsPosition as "top" | "bottom"} disableInteractive={true} arrow={true}
                        title={<span style={{fontSize: "12px"}}>{formatMessage("menu.edit.fontSize")}</span>}
                    >
                        <input type={"number"} min={1} max={999} className={"fontSizeInput"} value={fontSize}
                               onChange={(e) => {
                                   if (isNaN(parseInt(e.target.value))) return;
                                   if (parseInt(e.target.value) < 1) e.target.value = "1";
                                   if (parseInt(e.target.value) > 999) e.target.value = "999";
                                   setFontSize(parseInt(e.target.value));
                               }}
                        />
                    </Tooltip>
                    <div className={"separator"}></div>
                    <Tooltip
                        placement={tooltipsPosition as "top" | "bottom"} disableInteractive={true} arrow={true}
                        title={<span style={{fontSize: "12px"}}>{formatMessage("menu.edit.textColor")}</span>}
                    >
                        <div className={"popup-button textColorPicker"} style={{
                            backgroundColor: backgroundColor,
                            backgroundImage: (backgroundColor === "#00000000" ? `url(${transparentBg})` : "none"),
                            backgroundSize: "cover"
                        }}
                        onClick={() => setOpenBackgroundColorMenu(true)}></div>
                    </Tooltip>
                    <div className={"separator"}></div>
                    <Tooltip
                        placement={tooltipsPosition as "top" | "bottom"} disableInteractive={true} arrow={true}
                        title={<span style={{fontSize: "12px"}}>{formatMessage("menu.edit.alignLeft")}</span>}
                    >
                        <AlignHorizontalLeft
                            className={"popup-button"}
                            onClick={() => setTextAlign("Left")}
                        />
                    </Tooltip>
                    <Tooltip
                        placement={tooltipsPosition as "top" | "bottom"} disableInteractive={true} arrow={true}
                        title={<span style={{fontSize: "12px"}}>{formatMessage("menu.edit.alignCenter")}</span>}
                    >
                        <AlignHorizontalCenter
                            className={"popup-button"}
                            onClick={() => setTextAlign("Center")}
                        />
                    </Tooltip>
                    <Tooltip
                        placement={tooltipsPosition as "top" | "bottom"} disableInteractive={true} arrow={true}
                        title={<span style={{fontSize: "12px"}}>{formatMessage("menu.edit.alignRight")}</span>}
                    >
                        <AlignHorizontalRight
                            className={"popup-button"}
                            onClick={() => setTextAlign("Right")}
                        />
                    </Tooltip>
                    <div className={"separator"}></div>
                    <Tooltip
                        placement={tooltipsPosition as "top" | "bottom"} disableInteractive={true} arrow={true}
                        title={<span style={{fontSize: "12px"}}>{formatMessage("menu.edit.italic")}</span>}
                    >
                        <FormatItalicIcon
                            className={`popup-button${(disabledFontVariants) ? "-disabled" : ""}`}
                            onClick={() => {
                                if (!disabledFontVariants)
                                    setFontStyle((fontStyle === "normal") ? "italic" : "normal")
                            }}
                        />
                    </Tooltip>
                    <Tooltip
                        placement={tooltipsPosition as "top" | "bottom"} disableInteractive={true} arrow={true}
                        title={<span style={{fontSize: "12px"}}>{formatMessage("menu.edit.bold")}</span>}
                    >
                        <FormatBoldIcon
                            className={`popup-button${(disabledFontVariants) ? "-disabled" : ""}`}
                            onClick={() => {
                                if (!disabledFontVariants)
                                    setFontWeight((fontWeight === "normal") ? "bold" : "normal")
                            }}
                        />
                    </Tooltip>
                    <div className={"separator"}></div>
                    <Tooltip
                        placement={tooltipsPosition as "top" | "bottom"} disableInteractive={true} arrow={true}
                        title={<span style={{fontSize: "12px"}}>{formatMessage("menu.edit.lockPos")}</span>}
                    >
                        { (selectedObject?.draggable && selectedObject?.resizable) ?
                            <LockOpenIcon
                                className={"popup-button"}
                                onClick={() => toggleLocked()}
                            /> :
                            <LockIcon
                                className={"popup-button"}
                                onClick={() => toggleLocked()}
                            />
                        }
                    </Tooltip>
                    <Tooltip
                        placement={tooltipsPosition as "top" | "bottom"} disableInteractive={true} arrow={true}
                        title={<span style={{fontSize: "12px"}}>{formatMessage("menu.edit.flipToFront")}</span>}
                    >
                        <FlipToFrontIcon
                            className={"popup-button"}
                            onClick={() => flipObjectToFrontOrBack(1)}
                        />
                    </Tooltip>
                    <Tooltip
                        placement={tooltipsPosition as "top" | "bottom"} disableInteractive={true} arrow={true}
                        title={<span style={{fontSize: "12px"}}>{formatMessage("menu.edit.flipToBack")}</span>}
                    >
                        <FlipToBackIcon
                            className={"popup-button"}
                            onClick={() => flipObjectToFrontOrBack(-1)}
                        />
                    </Tooltip>
                    <Tooltip
                        placement={tooltipsPosition as "top" | "bottom"} disableInteractive={true} arrow={true}
                        title={<span style={{fontSize: "12px"}}>{formatMessage("menu.edit.delete")}</span>}
                    >
                        <DeleteIcon
                            className={"popup-button"}
                            onClick={() => deleteSelectedObject()}
                        />
                    </Tooltip>
                </div>
                <textarea
                    ref={editingTextAreaRef}
                    className={"editingTextArea"}
                    spellCheck={false}
                    style={{
                        position: "absolute",
                        backgroundColor: "transparent",
                        display: (showEditingTextArea) ? "block" : "none",
                        top: editingTextAreaPosition.top,
                        left: editingTextAreaPosition.left,
                        width: editingTextAreaSize.width,
                        height: editingTextAreaSize.height,
                        font: editingTextAreaFont,
                        color: editingTextAreaColor,
                        lineHeight: editingTextAreaLineHeight + "px",
                        textAlignLast: editingTextAreaAlignment as Property.TextAlignLast,
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        border: "solid blue 1px",
                        resize: "none",
                    }}
                    onFocus={(e) => {
                        e.target.setSelectionRange(e.target.value.length, e.target.value.length);
                        setSelectedObject(null);

                        if (editedTextAreaObject) {
                            setTextAreaPreviousContent(editedTextAreaObject.text ?? "");
                        }
                    }}
                    onBlur={(e) => {
                        if (!editedTextAreaObject) return;
                        setShowEditingTextArea(false);

                        if (!editedTextAreaObject?.text || editedTextAreaObject.text.length < 1) {
                            const textAreaToDelete = imprintBuilderObjects.current.findIndex(obj => obj.id === editedTextAreaObject.id);

                            if (textAreaToDelete) {
                                registerHistoryObjectAddition(imprintBuilderObjects.current[textAreaToDelete]);
                                imprintBuilderObjects.current.splice(textAreaToDelete, 1);
                            }
                        } else {
                            setSelectedObject(editedTextAreaObject);
                            registerHistoryIfTextAreaContentUpdated(editedTextAreaObject);
                        }

                        setEditedTextAreaObject(null);
                    }}
                    // TEMP: Prevents line breaks
                    onKeyDown={(e) => {
                        if (e.key === "Enter") e.preventDefault();
                    }}
                    // onInput updates canvas object text value and also handles automatic textarea resizing along with content and alignment.
                    onInput={(e) => {
                        if (!drawingCanvasRef.current) return;
                        const canvas = drawingCanvasRef.current;

                        const ctx = canvas.getContext("2d");
                        if (!ctx || !editedTextAreaObject || !editingTextAreaRef.current) return;

                        editedTextAreaObject.text = editingTextAreaRef.current.value;
                        const editedTextAreaDimensions = getTextAreaMinimalDimensions(ctx, editedTextAreaObject);

                        const currentTextAreaWidth = Math.ceil(editedTextAreaObject.width ?? 1);
                        const textContentWidth = Math.ceil(editedTextAreaDimensions.width);
                        const currentTextAreaHeight = Math.ceil(editedTextAreaObject.height ?? 1);
                        const textContentHeight = Math.ceil(editedTextAreaDimensions.height);

                        const widthDifference = textContentWidth - currentTextAreaWidth;

                        if (currentTextAreaWidth < textContentWidth) {
                            editedTextAreaObject.width = textContentWidth;
                            setEditingTextAreaSize({height: editingTextAreaSize.height, width: textContentWidth+5});

                            if (editedTextAreaObject.alignment === "Center") {
                                // Calculate the new left position to keep it centered
                                const newLeftPosition = editingTextAreaPosition.left - widthDifference / 2;
                                setEditingTextAreaPosition({top: editingTextAreaPosition.top, left: newLeftPosition});
                                editedTextAreaObject.x = newLeftPosition;
                            } else if (editedTextAreaObject.alignment === "Right") {
                                // Calculate the new left position to grow on left side
                                const newLeftPosition = editingTextAreaPosition.left - widthDifference;
                                setEditingTextAreaPosition({top: editingTextAreaPosition.top, left: newLeftPosition});
                                editedTextAreaObject.x = newLeftPosition;
                            }
                        }

                        if (currentTextAreaHeight < textContentHeight) {
                            editedTextAreaObject.height = textContentHeight;
                            setEditingTextAreaSize({height: textContentHeight + 3, width: editingTextAreaSize.width});
                        }
                    }}
                ></textarea>
                <input type="file" id="imageInsertInput" style={{display: "none"}} accept="image/png, image/jpeg" onChange={(e) => handleImageInsertInput(e)}/>
                <input type="file" id="imageReplaceInput" style={{display: "none"}} accept="image/png, image/jpeg" onChange={(e) => handleImageReplaceInput(e)}/>
            </div>
        </div>
    );

});

export default BSImprintBuilder;