import React, { useContext, useState } from "react";
import "./banner.style.css";
import {
  Box,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import StoreIcon from "@mui/icons-material/Store";

import { ReactComponent as LogoBanner } from "../assets/logo_banner.svg";
import { StepperContext } from "../contexts/stepper.context";
import { StorageContext } from "../contexts/storage.context";
import { Logout } from "@mui/icons-material";
import LangSelect from "./langselect.component";
import useFormatters from "../hooks/use-formatters";
import { Steps } from "./stepper.component";

function Banner() {
  const { palette, colors } = useTheme();
  const { formatMessage } = useFormatters();

  const { setStep } = useContext(StepperContext);
  const { refreshToken, distributorMail, setRefreshToken, setDistributorMail } =
    useContext(StorageContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    handleClose();
    setRefreshToken("");
    setDistributorMail("");
    setStep(Steps.LOGIN);
  };

  return (
    <header className="header">
      <div className="header__logo">
        <LogoBanner />
      </div>
      <div className="header__lang">
        <LangSelect />
      </div>
      <div className="header__user">
        {refreshToken && refreshToken !== "" && (
          <>
            <Button
              style={{
                padding: "0",
              }}
              id="distributor-button"
              aria-controls={open ? "distributor-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <StoreIcon htmlColor={"white"} fontSize={"large"} />
              <span>{distributorMail}</span>
            </Button>
            <Menu
              id="distributor-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "distributor-button",
              }}
            >
              <MenuItem
                onClick={handleLogout}
                sx={{ color: colors.red, width: "210px" }}
              >
                <ListItemIcon sx={{ color: colors.red }}>
                  <Logout fontSize="small" />
                </ListItemIcon>
                {formatMessage("menu.distributor.logout")}
              </MenuItem>
            </Menu>
          </>
        )}
      </div>
    </header>
  );
  /**
     
     
    return (
        <Box
            position={"sticky"}
            top={0} left={0}
            zIndex={1000}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            height={"100px"}
            bgcolor={palette.primary.light}
            boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}
        >
            <LangSelect style={{position: "absolute", left: "35px"}}/>

            <LogoBanner/>

            {key && key !== "" &&
                <>
                    <Button style={{
                            position: "absolute",
                            right: "50px",
                            color: "white",
                            padding: "10px",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            gap: "15px",
                            fontSize: "20px",
                            fontWeight: "bold"
                        }}
                        id="distributor-button"
                        aria-controls={open ? 'distributor-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <StoreIcon htmlColor={"white"} fontSize={"large"} />
                        <span>{distributorMail}</span>
                    </Button>
                    <Menu
                        id="distributor-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'distributor-button',
                        }}
                    >
                        <MenuItem onClick={handleLogout} sx={{color: colors.red, width: "210px"}}>
                            <ListItemIcon sx={{color: colors.red}}>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            {formatMessage("menu.distributor.logout")}
                        </MenuItem>
                    </Menu>
                </>
            }

        </Box>
    );
    */
}

export default Banner;
