export function resizeBase64Png(base64: string, maxSize: number = 65536): Promise<string> {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) throw new Error('Failed to get canvas context');

    const img = new Image();
    img.src = base64;

    return new Promise((resolve) => {
        img.onload = async () => {
            let width = img.width;
            let height = img.height;
            let scaleFactor = 0.9;

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);
            let newBase64 = canvas.toDataURL('image/png');

            while (newBase64.length > maxSize) {
                width *= scaleFactor;
                height *= scaleFactor;
                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);
                newBase64 = canvas.toDataURL('image/png');
            }

            resolve(newBase64);
        };
    });
}