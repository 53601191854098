import React, {createContext, useEffect, useState} from "react";
import {BystampApi} from "../network/bystamp-api";

type StorageContextType = {
    locale: string;
    distributorMail: string;
    refreshToken: string;
    accessToken: string;
    showWelcomeModal: boolean;
    setLocale(locale: string): void;
    setDistributorMail(mail: string): void;
    setRefreshToken(token: string): void;
    setAccessToken(token: string): void;
    setShowWelcomeModal(show: boolean): void;
}

export const StorageContext = createContext<StorageContextType>(
    {} as StorageContextType
);

const StorageProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
    const [locale, setLocale] = useState<string>(localStorage.getItem("locale") ?? (navigator.language || "en"));
    const [distributorMail, setDistributorMail] = useState<string>(localStorage.getItem("distributorMail") ?? "");
    const [refreshToken, setRefreshToken] = useState<string>(localStorage.getItem("refreshToken") ?? "");
    const [accessToken, setAccessToken] = useState<string>(localStorage.getItem("accessToken") ?? "");
    const [showWelcomeModal, setShowWelcomeModal] = useState<boolean>((localStorage.getItem("showWelcomeModal") === null ? true : localStorage.getItem("showWelcomeModal") === "true"));

    const api = new BystampApi();

    useEffect(() => {
        setRefreshToken(new URLSearchParams(window.location.search).get("code") ?? refreshToken);

        if (new URLSearchParams(window.location.search).get("code") || refreshToken) {
            // Check if RefreshToken works by requesting an AccessToken else remove refresh token to auto disconnect
            api.requestAppAccessToken(new URLSearchParams(window.location.search).get("code") ?? refreshToken).then(res => {
                if (!res.ok || !res.parsedBody) setRefreshToken("");

                if (res.parsedBody?.access_token) setAccessToken(res.parsedBody.access_token);
                else setRefreshToken("");
            }).catch(err => {
                console.error(err);
                setRefreshToken("");
            });
        }
    }, [])

    useEffect(() => {
        localStorage.setItem("locale", locale);
        localStorage.setItem("distributorMail", distributorMail);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("showWelcomeModal", showWelcomeModal.toString());
    }, [locale, distributorMail, refreshToken, accessToken, showWelcomeModal])

    return (
        <StorageContext.Provider
            value={{
                locale,
                distributorMail,
                refreshToken,
                accessToken,
                showWelcomeModal,
                setLocale,
                setDistributorMail,
                setRefreshToken,
                setAccessToken,
                setShowWelcomeModal
            }}
        >
            {children}
        </StorageContext.Provider>
    );
};

export default StorageProvider;
