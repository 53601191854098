import { Agent, HttpResponse } from "./agent";
import {ActivationCodeRequest} from "../components/steps/form.step";
import {DistributorLoginRequest} from "../components/steps/login.step";

const baseUrl = process.env.REACT_APP_BASEURL;

export const backendVersion = "/v1";

export class BystampApi {
    private agent = new Agent();

    private requestMapping = "/core/api/publisher";
    private authRequestMapping = backendVersion + "/auth/kdsm";

    async requestAppAccessToken(refreshToken: string): Promise<HttpResponse<{ access_token: string }>> {
        console.log("Requesting access token");
        return this.agent.post(
            `${baseUrl}${backendVersion}/auth/providers/refresh`,
            { token: refreshToken }
        );
    }

    signIn(
        body: DistributorLoginRequest,
        token?: string
    ): Promise<HttpResponse<string>> {
        return this.agent.post(`${baseUrl}${this.authRequestMapping}`, body, token, true);
    }

    generateActivationCode(
        body: ActivationCodeRequest,
        token?: string
    ): Promise<HttpResponse<any>> {
        return this.agent.post(`${baseUrl}${this.requestMapping}/keymo/activation`, body, token);
    }
}
