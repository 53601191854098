import React, {useContext, useEffect, useState} from "react";

import useFormatters from "../../hooks/use-formatters";
import {Box, Button, Card, Dialog, Stack, useTheme} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BSButton from "../button.component";

import {ReactComponent as MailSvg} from "../../assets/Mail.svg";
import {Steps} from "../stepper.component";
import {StepperContext} from "../../contexts/stepper.context";
import {StorageContext} from "../../contexts/storage.context";
import {BystampApi} from "../../network/bystamp-api";
import {useIntl} from "react-intl";

const LoginSuccessModal = () => {
    const {distributorMail} = useContext(StorageContext);
    const {
        step,
        showLoginSuccessModal,
        setShowLoginSuccessModal,
        setShowLoginFailModal,
    } = useContext(StepperContext);
    const {formatMessage} = useFormatters();
    const {colors} = useTheme();
    const intl = useIntl();

    const api = new BystampApi();

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [buttonLabel, setButtonLabel] = useState(
        formatMessage("stepper.button.sendAgain")
    );

    useEffect(() => {
        setButtonLabel(formatMessage("stepper.button.sendAgain"));
    }, [intl]);

    const handleClose = (
        e: any,
        reason: "backdropClick" | "escapeKeyDown" | "button"
    ) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") return;
        setShowLoginSuccessModal(false);
    };

    const sendAgain = () => {
        setButtonDisabled(true);
        setButtonLabel(formatMessage("stepper.button.sending"));

        api.requestAppAccessToken(process.env.REACT_APP_REFRESH_TOKEN || "").then((res) => {
            if (res.ok) {
                api.signIn({email: distributorMail, language: intl.locale}, res.parsedBody?.access_token).then((response) => {
                    if (response.status === 200) {
                        setShowLoginSuccessModal(true);
                    } else {
                        setShowLoginFailModal(true);
                    }
                }).catch((error) => {
                    console.error(error);
                    setShowLoginFailModal(true);
                });
            } else {
                console.error(res);
                setShowLoginFailModal(true);
            }
        }).catch((error) => {
            console.error(error);
            setShowLoginFailModal(true);
        });

        setTimeout(() => {
            setButtonLabel(formatMessage("stepper.button.sendAgain"));
            setButtonDisabled(false);
        }, 7000);
    };

    return (
        <Dialog
            open={showLoginSuccessModal && step === Steps.LOGIN}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={"md"}
            PaperProps={{
                style: {
                    maxWidth: "800px",
                    width: "100%",
                    backgroundColor: "transparent",
                },
            }}
        >
            <Card
                style={{
                    display: "flex",
                    flexDirection: "column",
                    overflow: "auto",
                }}
            >
                <Box
                    width={"100%"}
                    height={40}
                    bgcolor={colors.blue}
                    position={"relative"}
                >
                    <Button
                        onClick={() => handleClose(null, "button")}
                        style={{
                            position: "absolute",
                            right: 0,
                            top: 0,
                            padding: "0px 0px 0px 10px",
                            color: colors.white,
                            height: "40px",
                            borderRadius: 0,
                        }}
                    >
                        <CloseIcon/>
                    </Button>
                </Box>

                <Stack
                    direction="column"
                    spacing={3}
                    pt={2}
                    pb={2}
                    pl={5}
                    pr={5}
                    textAlign={"center"}
                >
                    <h2>{formatMessage("modal.login.success.title")}</h2>

                    <Box display={"flex"} justifyContent={"center"} padding={1}>
                        <MailSvg/>
                    </Box>

                    <p className="text-body">
                        {formatMessage("modal.login.success.content1")}
                    </p>
                    <p className="text-body" style={{fontWeight: "bold"}}>
                        {distributorMail}
                    </p>
                    <p className="text-body">
                        {formatMessage("modal.login.success.content2")}
                    </p>
                    <p className="text-body">
                        {formatMessage("modal.login.success.content3")}
                    </p>

                    <Stack direction={"row"} spacing={2} justifyContent={"center"}>
                        <BSButton disabled={buttonDisabled} onClick={() => sendAgain()}>
                            {buttonLabel}
                        </BSButton>
                    </Stack>
                </Stack>
            </Card>
        </Dialog>
    );
};

export default LoginSuccessModal;
